// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/Gilroy-Light.eot');
  src: url('../src/assets/fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/Gilroy-Light.woff2') format('woff2'),
    url('../src/assets/fonts/Gilroy-Light.woff') format('woff'),
    url('../src/assets/fonts/Gilroy-Light.ttf') format('truetype'),
    url('../src/assets/fonts/Gilroy-Light.svg#Gilroy-Light') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/Gilroy-LightItalic.eot');
  src: url('../src/assets/fonts/Gilroy-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/Gilroy-LightItalic.woff2') format('woff2'),
    url('../src/assets/fonts/Gilroy-LightItalic.woff') format('woff'),
    url('../src/assets/fonts/Gilroy-LightItalic.ttf') format('truetype'),
    url('../src/assets/fonts/Gilroy-LightItalic.svg#Gilroy-LightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/Gilroy-Regular.eot');
  src: url('../src/assets/fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/Gilroy-Regular.woff2') format('woff2'),
    url('../src/assets/fonts/Gilroy-Regular.woff') format('woff'),
    url('../src/assets/fonts/Gilroy-Regular.ttf') format('truetype'),
    url('../src/assets/fonts/Gilroy-Regular.svg#Gilroy-Regular') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/Gilroy-RegularItalic.eot');
  src: url('../src/assets/fonts/Gilroy-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/Gilroy-RegularItalic.woff2') format('woff2'),
    url('../src/assets/fonts/Gilroy-RegularItalic.woff') format('woff'),
    url('../src/assets/fonts/Gilroy-RegularItalic.ttf') format('truetype'),
    url('../src/assets/fonts/Gilroy-RegularItalic.svg#Gilroy-RegularItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/Gilroy-Medium.eot');
  src: url('../src/assets/fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/Gilroy-Medium.woff2') format('woff2'),
    url('../src/assets/fonts/Gilroy-Medium.woff') format('woff'),
    url('../src/assets/fonts/Gilroy-Medium.ttf') format('truetype'),
    url('../src/assets/fonts/Gilroy-Medium.svg#Gilroy-Light') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/Gilroy-MediumItalic.eot');
  src: url('../src/assets/fonts/Gilroy-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/Gilroy-MediumItalic.woff2') format('woff2'),
    url('../src/assets/fonts/Gilroy-MediumItalic.woff') format('woff'),
    url('../src/assets/fonts/Gilroy-MediumItalic.ttf') format('truetype'),
    url('../src/assets/fonts/Gilroy-MediumItalic.svg#Gilroy-MediumItalic') format('svg');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/Gilroy-SemiBold.eot');
  src: url('../src/assets/fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/Gilroy-SemiBold.woff2') format('woff2'),
    url('../src/assets/fonts/Gilroy-SemiBold.woff') format('woff'),
    url('../src/assets/fonts/Gilroy-SemiBold.ttf') format('truetype'),
    url('../src/assets/fonts/Gilroy-SemiBold.svg#Gilroy-SemiBold') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/Gilroy-SemiBoldItalic.eot');
  src: url('../src/assets/fonts/Gilroy-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/Gilroy-SemiBoldItalic.woff2') format('woff2'),
    url('../src/assets/fonts/Gilroy-SemiBoldItalic.woff') format('woff'),
    url('../src/assets/fonts/Gilroy-SemiBoldItalic.ttf') format('truetype'),
    url('../src/assets/fonts/Gilroy-SemiBoldItalic.svg#Gilroy-SemiBoldItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/Gilroy-Bold.eot');
  src: url('../src/assets/fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/Gilroy-Bold.woff2') format('woff2'),
    url('../src/assets/fonts/Gilroy-Bold.woff') format('woff'),
    url('../src/assets/fonts/Gilroy-Bold.ttf') format('truetype'),
    url('../src/assets/fonts/Gilroy-Bold.svg#Gilroy-Light') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/Gilroy-BoldItalic.eot');
  src: url('../src/assets/fonts/Gilroy-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/Gilroy-BoldItalic.woff2') format('woff2'),
    url('../src/assets/fonts/Gilroy-BoldItalic.woff') format('woff'),
    url('../src/assets/fonts/Gilroy-BoldItalic.ttf') format('truetype'),
    url('../src/assets/fonts/Gilroy-BoldItalic.svg#Gilroy-BoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/Gilroy-ExtraBold.eot');
  src: url('../src/assets/fonts/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/Gilroy-ExtraBold.woff2') format('woff2'),
    url('../src/assets/fonts/Gilroy-ExtraBold.woff') format('woff'),
    url('../src/assets/fonts/Gilroy-ExtraBold.ttf') format('truetype'),
    url('../src/assets/fonts/Gilroy-ExtraBold.svg#Gilroy-ExtraBold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/Gilroy-Black.eot');
  src: url('../src/assets/fonts/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/Gilroy-Black.woff2') format('woff2'),
    url('../src/assets/fonts/Gilroy-Black.woff') format('woff'),
    url('../src/assets/fonts/Gilroy-Black.ttf') format('truetype'),
    url('../src/assets/fonts/Gilroy-Black.svg#Gilroy-ExtraBold') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../src/assets/fonts/Gilroy-BlackItalic.eot');
  src: url('../src/assets/fonts/Gilroy-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../src/assets/fonts/Gilroy-BlackItalic.woff2') format('woff2'),
    url('../src/assets/fonts/Gilroy-BlackItalic.woff') format('woff'),
    url('../src/assets/fonts/Gilroy-BlackItalic.ttf') format('truetype'),
    url('../src/assets/fonts/Gilroy-BlackItalic.svg#Gilroy-Black') format('svg');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}


html,
body {
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: none;

  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */


}

@media screen and (max-device-width: 950px) {
  body {
    -webkit-text-size-adjust: none;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Gilroy', sans-serif;
  font-family: 'Gilroy', sans-serif;
  user-select: none;
  -webkit-user-select: none;
}


.App {
  background: black;

  .phone-rotation-indication-div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 11;
    background-color: #333;
  }

  .whats-app-div {
    position: fixed;
    top: 88vh;
    right: 2%;
    width: 4vw;
    height: 4vw;
    border-radius: 50%;
    z-index: 16;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: 0.2s cubic-bezier(.23, .16, 0, .99);

    &:hover {
      transform: scale(1.05);
    }

  }



}

//--------------------------  max-width: 600px  --------------------------------------
@media screen and (max-device-width: 950px) {


  .App {
    .whats-app-div {
      top: 80vh;
      right: 2%;
      width: 6.5vw;
      height: 6.5vw;
    }

  }
}
