.benefits-main {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  transition: 1.5s ease-in-out;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;

  .benefits-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;

    .benefits-s1 {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      overflow: hidden;

      h2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 600%);
        font-size: 4vw;
        font-weight: 900;
        background-image: url("https://rentblob.blob.core.windows.net/agri-food-tech/sponsor/text-bg-clip.png");
        background-size: 10%;
        background-position: center;
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        transition: 2s ease-in-out;
        z-index: 3;
        letter-spacing: -2px;
        // animation: BgPositionAnim  4s ease-in-out;
      }

      .s1-mockup {
        top: 35%;
        left: 38%;
        width: 180vw;
        height: 150vw;
        transform: translate(-50%, -50%) rotate(-5deg);
        position: absolute;
        transition: 2s ease-in-out;
        z-index: 1;
      }

      .s1-overlay1 {
        position: absolute;
        z-index: 2;
        top: -20%;
        right: -20%;
        width: 150%;
        height: 220%;
        transform-origin: right;
        transform: rotate(25deg);
        transition: 3s ease-in-out;
        background-color: black;
      }

      .s1-overlay2 {
        position: absolute;
        z-index: 3;

        width: 160%;
        height: 270%;
        transform-origin: right;
        transform: translate(-120%, -10%) rotate(25deg);
        transition: 2.5s ease-in-out;
        background-color: black;
      }
    }

    // -------------- Slide Two ----------------

    .benefits-s2 {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;

      .s2-heading-wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100vh;
        transition: 2s ease-in-out;
        opacity: 0;

        .h-two {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 4vw;
          font-weight: 900;
          background-image: url("https://rentblob.blob.core.windows.net/agri-food-tech/sponsor/text-bg-clip.png");
          background-size: 10%;
          background-position: center;
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          transition: 1s cubic-bezier(.36,.1,.01,.99);
          z-index: 2;
          letter-spacing: -2px;

        }

      }

      .s2-image-wrapper {
        .s2-audeience-left {
          position: absolute;
          bottom: 8vw;
          left: 2%;
          width: 40vw;
          z-index: 1;
          transition: 1.5s ease-in-out;
          transform: translate(0%, 120vh) scaleY(-1);
          // cursor: pointer;
          opacity: 0.5;
          transform-origin: bottom;
        }

        .s2-audeience-right {
          position: absolute;
          bottom: 8vw;
          right: 2%;
          width: 40vw;
          z-index: 1;
          opacity: 0.5;
          transition: 1.5s ease-in-out;
          transform: translate(0%, 120vh) scaleY(-1);
          // cursor: pointer;
          transform-origin: bottom;
        }
      }

      .bottom-circle-div {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(200vh);
        transition: 1.5s ease-in-out;

        // transform-origin: right;
        .bottom-imgs {
          width: 100%;
          height: 100%;
          position: relative;
          margin-top: 54vw;
          transition: 1s cubic-bezier(.36,.1,.01,.99);

          .green-overlay {
            left: 49%;

          }

          img {
            width: 50vw;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}

@keyframes BgPositionAnim {
  0% {
    background-position-x: 150;
  }

  100% {
    background-position-x: 0;
  }
}