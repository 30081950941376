.curated-meeting-comp-main-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    transition: 1.8s cubic-bezier(.23, .16, 0, .99);
    // z-index: 6;
    overflow: hidden;
    transform: translateY(0vh) scale(0.4) rotate(-85deg);
    will-change: transform;
    opacity: 0;

    .curated-meeting-comp-main {
        position: relative;
        width: 100%;
        height: 100%;
        // background-color: #000;
        display: flex;
        justify-content: center;
        align-items: center;

        .content-main-div {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 1.8s cubic-bezier(.23, .16, 0, .99);
            will-change: transform;
            // transform: scale(0.5) rotate(-85deg);


            // -------------------------------------------------------------------------
            .circle-content-container {
                position: absolute;
                width: 35vw;
                height: 35vw;
                // border: 1px solid #fff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1px;

                .before-div {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(233deg, #69FF6A 18.78%, #000 28.03%);
                    border-radius: 50%;

                }

                .bg-circle {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    background-color: #000;
                    border-radius: 50%;
                }
            }

            // -------------------------------------------------------------------------
            .video-card-one {
                position: absolute;
                width: 15vw;
                height: 12.5vw;
                left: 15%;
                transform: rotate(-10deg);
                margin-top: -24%;
                margin-left: 16%;
                z-index: 1;

                .another-img{
                   min-width: 110%;
                   margin-top: 4vh;
                }

                .video-card-one-img {
                    position: absolute;
                    width: 100%;
                    object-fit: contain;
                    opacity: 0;
                    transform: scale(1.2) rotate(15deg) translate(-2vw, -3vh);
                    transition: 1.6s cubic-bezier(.23, .16, 0, .99);

                }

            }

            // -------------------------------------------------------------------------
            .video-card-two {
                position: absolute;
                width: 15vw;
                height: 12vw;
                right: 15%;
                transform: rotate(0deg);
                margin-bottom: -27%;
                margin-right: 20%;
                z-index: 1;

            }

            // -------------------------------------------------------------------------
            .video-card-three {
                position: absolute;
                width: 20vw;
                height: 12vw;
                border-radius: 1vw;
                margin-top: -20%;
                margin-left: 40%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                padding: 1px;
                background: linear-gradient(122deg, #0DF40F 14.03%, #000 27.11%);
                z-index: 1;
                transform: rotate(4deg);



                .before-div {
                    position: absolute;
                    width: 99%;
                    height: 99%;
                    border-radius: 1vw;
                    background-color: #000;

                }

                .inner-div {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(180deg, #090909 0.15%, rgba(78, 78, 78, 0.77) 99.85%);
                    border-radius: 1vw;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 5%;
                    padding: 2%;


                    .inner-video-frame {
                        position: relative;
                        width: 10vw;
                        height: 7vw;
                        // background-color: #fff;
                        border-radius: 0.5vw;
                        box-shadow: 0 0.2vw 4px #000000ab;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .video-palyer {
                            min-width: 125%;
                            height: 100%;
                        }

                    }


                    .text-div {
                        width: 100%;
                        height: 30%;
                        background-color: #000;
                        border-radius: 0.5vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        font-size: 1.2vw;

                    }

                    .dummy-data-div {
                        position: absolute;
                        top: 8%;
                        left: 58%;
                        width: 30%;
                        height: 30%;
                        // background-color: #fff;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        opacity: 0.4;

                        .hr-line {
                            width: 100%;
                            height: 3px;
                            background-color: #888888;
                            border: none;
                        }

                    }



                }
            }

            // -------------------------------------------------------------------------


            .video-card-four {
                position: absolute;
                width: 20vw;
                height: 12vw;
                border-radius: 1vw;
                margin-top: 20%;
                margin-left: -40%;
                display: flex;
                justify-content: center;
                align-items: center;
                // overflow: hidden;
                padding: 1px;
                background: linear-gradient(202deg, #0DF40F 14.03%, #000 27.11%);
                z-index: 1;
                transform: rotate(2deg);


                .before-div {
                    position: absolute;
                    width: 99%;
                    height: 99%;
                    border-radius: 1vw;
                    background-color: #000;

                }

                .inner-div {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(180deg, #090909 0.15%, rgba(78, 78, 78, 0.77) 99.85%);
                    border-radius: 1vw;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 5%;
                    padding: 2%;

                    .video-frame {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        width: 100%;
                        height: 70%;

                        .inner-video-frame-one {
                            position: relative;
                            width: 48%;
                            min-height: 130%;
                            max-height: 130%;
                            border-radius: 2vw 0 0 0;
                            box-shadow: 0 0.2vw 4px #000000ab;
                            overflow: hidden;
                            display: flex;
                            justify-content: center;
                            align-items: center;


                            .video-palyer {
                                min-width: 125%;
                                height: 100%;
                                touch-action: none;

                            }

                        }

                        .inner-video-frame-two {
                            position: relative;
                            width: 48%;
                            height: 100%;
                            background-color: #fff;
                            border-radius: 0 2vw 0 0;
                            box-shadow: 0 0.2vw 4px #000000ab;
                            overflow: hidden;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .video-palyer {
                                min-width: 120%;
                                height: 100%;
                                touch-action: none;

                            }

                        }
                    }

                    .text-div {
                        width: 100%;
                        height: 30%;
                        background-color: #000;
                        border-radius: 0.5vw;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        font-size: 1.2vw;

                    }



                }
            }

            // -----------------------------------------------------------------------------


            .vector-div {
                position: absolute;
                width: 10vw;
                height: 10vw;
                // border: 1px solid #fff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .vector-img {
                    position: relative;
                    width: 95%;
                    height: 95%;
                    object-fit: contain;
                    transform: rotate(-35deg);
                    z-index: 1;
                    transition: 1.6s ease-out;

                }

                .hr-line-one {
                    position: absolute;
                    width: 30vw;
                    border-bottom: 0.22vw solid #C9C9C9;
                    opacity: 0.2;
                    border-bottom-style: dashed;
                    transform: rotate(-25deg);
                    margin-top: 5vh;
                    z-index: 0;

                }

                .hr-line-two {
                    position: absolute;
                    width: 30vw;
                    border-bottom: 0.22vw solid #0df411;
                    opacity: 0.4;
                    border-bottom-style: dashed;
                    transform: rotate(55deg);
                    z-index: 0;

                }

                .text-para {
                    position: absolute;
                    color: #fff;
                    font-size: 1.5vw;
                    line-height: 1.2;
                    z-index: 1;
                    font-weight: 500;
                    text-align: center;
                    opacity: 0;
                    transition: 1.6s cubic-bezier(.23, .16, 0, .99);
                    text-shadow: 0 0.2vw 2px #000000d0;

                }

                .para-second {
                    position: absolute;
                    color: #fff;
                    font-size: 1.2vw;
                    line-height: 1.2;
                    z-index: 1;
                    font-weight: 400;
                    text-align: center;
                    top: 100%;
                    opacity: 0;
                    transition: 1.6s cubic-bezier(.23, .16, 0, .99);


                    span {
                        color: #69FF6A;
                    }

                }

                .para-third {
                    position: absolute;
                    color: #fff;
                    font-size: 1.5vw;
                    line-height: 1.2;
                    z-index: 1;
                    font-weight: 500;
                    text-align: center;
                    opacity: 0;
                    transition: 1.6s cubic-bezier(.23, .16, 0, .99);
                    text-shadow: 0 0.2vw 2px #000000d0;


                }
            }

            // ---------------------------------------------------------

        }


        .text-content-container {
            position: absolute;
            right: 5%;
            width: 45%;
            // height: 40%;
            // border: 1px solid #fff;
            display: flex;
            flex-direction: column;
            gap: 5%;
            align-items: flex-start;
            justify-content: flex-start;
            opacity: 0;
            transition: 1.8s cubic-bezier(.23, .16, 0, .99);
            transform: translate(50vw);
            margin-top: -8vh;

            .text-img{
                width: 100%;
                object-fit: contain;
            }

            // .para-one {
            //     color: #fff;
            //     text-transform: capitalize;
            //     font-size: 1.5vw;
            //     font-style: italic;

            //     span {
            //         color: #0df411;
            //     }
            // }

            // .para-two {
            //     color: #fff;
            //     text-transform: capitalize;
            //     font-size: 1.5vw;
            //     font-style: italic;

            //     span {
            //         color: #0df411;
            //     }
            // }
        }
    }

}