.agenda-page-main-container {
    width: 100%;
    position: relative;

    .agenda-page-main {
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: #000;
        color: white;
        overflow: hidden;
        transition: 1.8s ease-out;

        .hyderabad-bg {
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            height: 100%;
            object-fit: cover;
        }

        .agenda-page-main-left {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100vh;
            padding: 0% 3%;

            .vector-pair {
                position: absolute;
                width: 60vw;
                height: 40vw;
                padding: 0% 4%;
                transform: translate(-37vw, -35vh);
            }

            .agri-logo {
                width: 8vw;
                height: 8vw;
                z-index: 0;
                cursor: pointer;
            }

            .water-drop-bg {
                position: absolute;
                top: 30%;
                width: 45%;
                mix-blend-mode: hard-light;
            }

            .agenda-page-main-heading {
                width: 100%;
                margin-top: -1.5vw;
                font-size: 4vw;
                font-weight: 500;
                z-index: 0;

                .event-italic-text {
                    font-style: italic;
                    font-weight: 200;
                    font-size: 3.5vw;
                }

                .agenda-text {
                    hr {
                        position: absolute;
                        width: 19%;
                        height: 0.02em;
                        background-color: #fff;
                        margin-top: -1%;
                        margin-left: 16%;
                    }
                }

                .hyderabad-text-div {
                    display: flex;
                    align-items: center;
                    margin-left: 2%;

                    .hyderabad-text {
                        margin-left: 4%;
                        font-size: 4.75vw;
                        text-transform: uppercase;
                        color: #59AF36;
                        -webkit-transform: scale(1, 1.35);
                        /* Safari and Chrome */
                        -moz-transform: scale(1, 1.35);
                        /* Firefox */
                        -ms-transform: scale(1, 1.35);
                        /* IE 9 */
                        -o-transform: scale(1, 1.35);
                        /* Opera */
                        transform: scale(1, 1.35);
                        /* W3C */
                    }

                    .arrow-icon {
                        height: 7vw;
                        width: 5vw;
                    }
                }
            }

            .event-date-div {
                display: flex;
                flex-direction: column;
                text-align: right;
                margin-top: 2%;
                margin-right: 18%;
                z-index: 1;
                transition: 1.2s ease-out;

                .event-date-text {
                    font-size: 1.25vw;
                    font-weight: 400;
                    margin-left: 2%;
                    color: #37CAEE;
                }

                .event-sub-text {
                    font-size: 1.1vw;
                    font-weight: 400;
                    margin-left: 2%;
                    color: #ED9C21;
                }
            }

            .theme-div {
                display: flex;
                flex-direction: column;
                margin-top: 2%;
                z-index: 1;
                transition: 1.2s ease-out;


                .theme-text {
                    font-size: 1.25vw;
                    font-weight: 400;
                    margin-left: 2%;
                    color: #37CAEE;

                }

                .theme-sub-text {
                    font-size: 1.1vw;
                    font-weight: 400;
                    margin-left: 2%;
                    color: #ED9C21;

                }
            }

            .location-div {
                display: flex;
                flex-direction: column;
                text-align: right;
                margin-top: 2%;
                margin-right: 18%;
                z-index: 1;
                transition: 1.2s ease-out;


                .location-text {
                    font-size: 1.25vw;
                    font-weight: 400;
                    margin-left: 2%;
                    color: #37CAEE;
                }

                .location-sub-text {
                    font-size: 1.1vw;
                    font-weight: 400;
                    margin-left: 2%;
                    color: #ED9C21;
                }
            }

            .time-div {
                display: flex;
                flex-direction: column;
                margin-top: 2%;
                z-index: 1;
                transition: 1.2s ease-out;


                .time-text {
                    font-size: 1.25vw;
                    font-weight: 400;
                    margin-left: 2%;
                    color: #37CAEE;

                }

                .time-sub-text {
                    font-size: 1.1vw;
                    font-weight: 400;
                    margin-left: 2%;
                    color: #ED9C21;
                }
            }

            .btn-div {
                position: absolute;
                bottom: 6%;
                margin-top: 1.5vw;
                margin-left: 1vw;
                width: 10vw;
                border-radius: 14px;
                padding: 0.09em;
                background: linear-gradient(to left, #06c209 0%, grey);
                z-index: 1;
                transition: 1.2s ease-out;

                .timeline-btn {
                    width: 100%;
                    background-color: black;
                    padding: 0.3em;
                    border-radius: 12px;
                    color: #fff;
                    font-size: 1.25vw;
                    font-weight: 300;
                    cursor: pointer;
                }
            }
        }
    }

    .timeline-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.5;
        z-index: 0;
        transition: 1.2s ease-out;
    }

    .timeline-div-container {
        position: absolute;
        width: 100%;
        height: 80vh;
        top: 20vh;
        background: linear-gradient(to bottom, #06c209 0%, transparent);
        border-radius: 3em 3em 0 0;
        transition: 1.2s ease-out;

        .timeline-div {
            position: fixed;
            width: 99.7%;
            height: 80vh;
            margin-top: 0.1%;
            margin-left: 0.15%;
            // border-top: 2px solid #06c209;
            // border-left: 2px solid #06c209;
            // border-right: 2px solid #06c209;
            border-radius: 3em 3em 0 0;
            background-color: #1d1d1d;
            opacity: 0.95;
            z-index: 2;
            display: flex;
            align-items: center;
            overflow: hidden;
            transition: 1.2s ease-out;

            .green-hemisphere {
                position: absolute;
                margin-top: -82vh;
                left: 7%;
                transition: 1.2s ease-out;
            }

            .green-knot {
                position: absolute;
                margin-top: 150vh;
                margin-left: 30vw;
                width: 30vw;
                transition: 1.2s ease-out;
            }

            .close-btn {
                position: absolute;
                font-size: 2vw;
                color: #06c209;
                top: 1vh;
                right: 2vw;
                z-index: 2;
                cursor: pointer;
            }

            .time-bg {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0.03;
                z-index: -1;
            }

            .progress-bar-div {
                margin-left: -5%;

                img {
                    width: 100%;
                }
            }

            .time-div {
                position: absolute;
                left: 15%;
                display: flex;
                align-items: center;
                color: white;
                gap: 10vw;
                width: 100%;
                height: 100%;
                z-index: 1;
                transition: 1.2s ease-out;

                .time-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .time-block-div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 5vw;
                    margin-top: -2%;
                    color: #7B7B7B;
                    filter: blur(2px);

                    .card-bg {
                        position: absolute;
                        width: 17vw;
                    }

                    .big-card-bg {
                        position: absolute;
                        width: 30vw;
                    }

                    .time-text {
                        font-size: 3.5vw;
                        font-weight: 600;
                        z-index: 1;
                        white-space: nowrap;
                        transition: 1.2s ease-out;
                        color: transparent;
                        background-image: url('https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/time-bg.png');
                        background-size: 400% 600%;
                        background-position: 100% 100%;
                        background-clip: text;
                        -webkit-background-clip: text;
                    }

                    .sub-text {
                        font-size: 1.25vw;
                        font-weight: 300;
                        text-align: center;
                        z-index: 1;
                        transition: 1.2s ease-out;
                    }

                    .card-time-text {
                        font-size: 1.5vw;
                        font-weight: 600;
                        z-index: 1;
                    }

                    .card-sub-text {
                        font-size: 1vw;
                        font-weight: 300;
                        text-align: center;
                        z-index: 1;
                        transition: 1.2s ease-out;
                    }

                }

                .parallel-heading {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-size: 2.5vw;
                    font-weight: 600;
                    z-index: 1;
                    color: transparent;
                    background-image: url('https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/time-bg.png');
                    background-size: 400% 600%;
                    background-position: 100% 100%;
                    background-clip: text;
                    -webkit-background-clip: text;
                    white-space: nowrap;
                    transition: 1.2s ease-out;
                }

                .parallel-timeline-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    width: 100%;
                }

                .bottom-parallel-timeline {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 20%;
                }

                .top {
                    margin-left: 1vw;
                    gap: 1vw;
                    filter: blur(0px);
                }

                .bottom {
                    margin-top: 15vw;
                    margin-left: 1vw;
                    gap: 1vw;
                    filter: blur(0px);
                }

                .closing-heading {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-size: 2.5vw;
                    font-weight: 600;
                    z-index: 1;
                    color: transparent;
                    background-image: url('https://rentblob.blob.core.windows.net/agri-food-tech/phygital-imgs/time-bg.png');
                    background-size: 400% 600%;
                    background-position: 100% 100%;
                    background-clip: text;
                    -webkit-background-clip: text;
                    white-space: nowrap;
                    transition: 1.2s ease-out;

                    .subtext {
                        color: white;
                        font-weight: 300;
                        font-size: 1.5vw;
                    }
                }
            }
        }
    }

}