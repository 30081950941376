.pform-container {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);

  .hidden-layer {
    position: absolute;
    width: 100%;
    height: 100%;

  }

  .pform-row {
    border: 1px solid gray;
    width: 70%;
    height: 80%;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 0.5vw;
    background-color: black;

    .form-close {
      position: absolute;
      top: 2%;
      right: 3%;
      color: #3be95f;
      cursor: pointer;
      font-size: 2.5vw;
      font-weight: 800;
    }

    .pcontact-heading {
      line-height: 2vw;
      font-weight: 500;
      font-size: 2.5vw;
      color: rgb(221, 220, 220);
    }

    .pform_section {
      width: 100%;
      //   height: 100%;
      text-align: center;
      margin: 1vw auto;

      .pform-controller {
        width: 100%;
        height: 88%;
        display: flex;
        align-items: center;

        justify-content: center;
        flex-wrap: wrap;

        .pcol-one,
        .pcol-two,
        .pcol-three,
        .pcol-four,
        .pcol-five,
        .pcol-six,
        .pcol-seven,
        .pcol-eight {
          width: 45%;

          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0.8vw 0vw;
          color: white;

          .input-styling {
            color: gray;
            width: 90%;

            outline: none;
            font-size: 1.2vw;
            border: none;
            padding: 1vw 1vw;
            border-radius: 0.4vw;
            background: transparent;
            border: 1px solid gray;
          }

          .col-eight {
            select {

              // background-color: transparent;
              option {
                // color: white;
                background-color: transparent;
              }
            }
          }
        }
      }

      .psubmit-btn {
        padding: 0.8vw 5vw;
        border: none;
        outline: none;
        width: 40%;
        border-radius: 0.3vw;
        font-size: 1.5vw;
        position: relative;
        margin: 1vw auto;

        background-color: #0bd90d;
        cursor: pointer;

        &:hover {
          background-color: #0bd90d;
        }

      }
    }
  }
}

// @media only screen and (max-width: 1200px) {
//   .form-container {
//     height: 60vh;
//   }
// }
// @media only screen and (max-width: 1000px) {
//   .form-container {
//     height: 50vh;
//   }
// }