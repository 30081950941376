.aicomp-main {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  .aicomp-div {
    width: 100%;
    height: 100%;
    position: relative;

    color: white;
    .ai-text {
      width: 30%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 1s ease-out;
      opacity: 0;
    }

    .ai-content-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 2s ease-in-out;
      .ai-green {
        width: 20%;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 2s ease-out;
        z-index: 2;
        opacity: 0;
      }
      .ai-green2 {
        width: 10%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-650%, 200%) scale(0.3) rotate(45deg);
        transition: 2.2s ease-out;
        z-index: 2;
      }
      .connect-text {
        width: 7%;
        position: absolute;
        top: 120%;
        left: 50%;
        z-index: 3;
        transition: 2s ease-out;
        transform: translate(-50%, -50%);
        filter: drop-shadow(1vw 1vw 2vw #000000);
      }
      .ai-based {
        position: absolute;
        font-size: 2.2vw;
        margin-top: 24vw;
        margin-left: -14.5vw;
        font-weight: 500;
        opacity: 0;
        transition: 2s ease-out;
      }
      .ai-text-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: 2s ease-out;
        transform: translateY(50%);
        .ai-htwo {
          font-size: 2.2vw;
          position: absolute;
          margin-top: 24vw;
          margin-left: 9vw;
          font-weight: 500;
          transition: 2s ease-out;
        }
        .line-bar {
          width: 24%;
          height: 0.2vh;
          border-radius: 1vw;
          position: absolute;
          margin-top: 26.8vw;
          transition: 2.2s ease-out;
        }
        .imagine-text,
        .aftc-text,
        .itconnect-text {
          position: absolute;
          width: 35%;
          margin-top: 32vw;
          transition: 1.2s ease-out;
        }
        .aftc-text {
          opacity: 0;
        }
        .itconnect-text {
          opacity: 0;
          transition: 1.5s ease-out;
          margin-top: 30.5vw;
          transform: translateY(500%);
        }
      }
    }
    .ai-frame-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .ai-phone-mockup {
        .ai-phone {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 18%;
          transform: translate(-50%, -50%);
          opacity: 0;
          transition: 1.6s ease-in-out;

        }
        .schedule-text{
            position: absolute;
            margin-top: 14.9vw;
            margin-left: 11vw;
            width: 12%;
            opacity: 0;
            transition: 1s ease-in-out;
        }
        .line-bar2{
            position: absolute;
            margin-top: 18vw;
            margin-left: 7vw;
            width: 22%;
            opacity: 0;
            transition: 1s ease-out 0.5s; 
        }
      }
      .frame-images {
        width: 100%;
        height: 100%;
        left: -31vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 2;
        transition: 1.5s ease-out;
        transform: translateY(100%) skew(0deg, 25deg);
        .frame-img1, .frame-img2, .frame-img3 {
          // position: absolute;
          width: 13.5vw;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2vw;
          transition: 1.5s ease-in-out;
          p{
            text-align: center;
            font-size: 1.5vw;
            transition: 2s ease-in-out;
            transform: translateX(-20vw);
          }
          img{
            width: 100%;
               margin: 0.5vw 0vw;
          transition: 1.5s ease-in-out;
          }
        }
      }
      .frame-img-extra {
        position: absolute;

        margin-left: 59.5vw;
        margin-top: 11vw;
        width: 10vw;
        transform: rotate(3deg);
        transition: 1.5s ease-out;
        opacity: 0;
        z-index: 2;
        transform: translatex(250%);
      }
      .ai-line-bars {
        position: absolute;
        width: 50vw;
        height: 25vw;

        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: 1s ease-out;
        img {
          position: absolute;
          z-index: 1;
          opacity: 0.8;
        }
        .ai-line1 {
          top: 0.5vw;
          left: 4vw;
          width: 90%;
          height: 70%;
          z-index: 1;
        }
        .ai-line2 {
          top: 11.8vw;
          width: 65%;
          height: 28%;
          left: 2vw;
        }
        .ai-line3 {
          top: 16vw;
          left: 0vw;
          width: 70%;
          height: 30%;
        }
      }
      .match-data {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translate(-30%);
        transition: 1.5s ease-out;

        .md-top {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          // position: absolute;
          transition: 2s ease-in-out;
          top: 19vh;
          // margin-top: 12vw;
          left: -39vw;
          // img {
          //   width: 4%;
          //   position: absolute;
          //   z-index: 1;
          // }
          p {
            position: absolute;
            z-index: 2;
            font-size: 1.5vw;
            text-align: center;
          }
          span {
            z-index: 2;
            font-size: 1.5vw;
            transform: translateY(100%);
          }
        }
        .md-center {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          // position: absolute;
          top: 40%;
          left: -39vw;
          // img {
          //   width: 4%;
          //   position: absolute;
          //   z-index: 1;
          // }
          p {
            position: absolute;
            z-index: 2;
            font-size: 1.5vw;
            text-align: center;
          }
          span {
            z-index: 2;
            font-size: 1.5vw;
            transform: translateY(100%);
          }
        }
        .md-bottom {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          // position: absolute;
          top: 60%;
          left: -39vw;
          // img {
          //   width: 4%;
          //   position: absolute;
          //   z-index: 1;
          // }
          p {
            position: absolute;
            z-index: 2;
            font-size: 1.5vw;
            text-align: center;
          }
          span {
            z-index: 2;
            font-size: 1.5vw;
            transform: translateY(100%);
          }
        }
      }
      .last-green-shape {
        .left-green-shape {
          position: absolute;
          width: 10%;
          top: 43vh;
          left: 33vw;
          transform: translate(0%,70vh) scale(0.5);
          transition: 1.6s ease-out;

        }
        .right-green-shape {
          position: absolute;
          width: 10vw;
          top: 43vh;
          right: 34vw;
          transition: 1.6s ease-out;
          transform: translate(0%,70vh) scale(0.5);
        }
      }
      .phone-mockup-extra{
        position: absolute;
        width: 100%;
        height: 100%;
 
        margin-left: 140vw;
        transition: 1.5s ease-in-out;
        z-index: 1;
    
     
        display: flex;
        align-items: center;
        justify-content: baseline;
   

        img{
          width: 18%;
          transition: 1.5s ease-in-out;
          transform: translate(0%,100vh);
        }
      }
    }
  }
}
