@mixin trans($delay: 0s) {
  transition: 2.5s cubic-bezier(1, 0, 0, 1) $delay;
}

.main-container-optimize {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  transition: 3s ease-in-out;

  .optimize-block-outer-layer-container {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    // border:5px solid red;
    transition: 1.5s cubic-bezier(1, 0, 0, 1);

    .bg-img-optimize {
      transition: 2s ease-in-out;
      opacity: 0;
    }

    .optimize-block {
      width: 65%;
      height: 50vh;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      @include trans();
      // border: 2px solid blue;

      .small-green-block-container {
        width: 100vw;
        height: 100vh;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        // border:5px solid red;
        @include trans();

        .small-green-blocks {
          width: 1.35vw;
          // position: absolute;
          margin-top: -25vw;
          // margin-top: 12.25vw;
          z-index: 2;
          opacity: 0;
          @include trans();
        }
      }

      .with-txt {
        width: 11.5%;
        position: absolute;
        top: 71%;
        left: 19%;
        opacity: 0;
        z-index: 1;
        @include trans();
      }
      .green-shadow {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        // transition: 0.8s ease-in;
        @include trans();
      }

      .optimize-time-txt {
        width: 65%;
        position: absolute;
        opacity: 0;
        @include trans();
        transform: translate(1050%, 600%) scale(26);
      }
      .green-fudge {
        width: 2.5%;
        position: absolute;
        top: 39.25%;
        left: 49%;
        opacity: 0;
        transform: rotate(-15deg);
        @include trans();
        z-index: 2;
      }
      .vector1 {
        width: 20%;
        position: absolute;
        top: 84%;
        left: 20%;
        @include trans(0.5s);
        opacity: 0;
      }
      .vector2 {
        width: 16%;
        position: absolute;
        top: 30%;
        right: 2%;
        @include trans(0.5s);
        opacity: 0;
      }
      .vector3 {
        width: 7%;
        position: absolute;
        top: 77%;
        left: 53%;
        @include trans(0.5s);
        opacity: 0;
      }
      .left-photo {
        width: 35%;
        position: absolute;
        top: 750%;
        left: 20%;
        opacity: 0;
        transition: 2.5s ease-in-out;
        transform: scale(7.2);
        // border:1px solid red;
      }
      .middle-photo {
        width: 32%;
        position: absolute;
        top: 550%;
        left: -40%;
        opacity: 0;
        transition: 2.5s ease-in-out;
        transform: scale(7.8);
        z-index: 2;
        // border:1px solid red;
      }
      .right-photo {
        width: 32%;
        position: absolute;
        top: 535%;
        right: -150%;
        opacity: 0;
        transition: 2.5s ease-in-out;
        transform: scale(7.2);
        // border:1px solid red;
      }
    }
  }
  .people-background-container {
    width: 100%;
    height: 100vh;
    position: absolute;
    // border: 2px solid yellow;
    opacity: 0;
    transition: 1s ease-in-out;

    .bg-People-Img {
      width: 100%;
      height: 100vh;
      position: absolute;
      // border: 2px solid green;
    }

    .benefit-txt {
      width: 20%;
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 2s cubic-bezier(1, 0, 0, 1);
      opacity: 0;
    }
    .green-rect {
      width: 21%;
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 2s cubic-bezier(1, 0, 0, 1);
      opacity: 0;
    }
    .iphone-container {
      width: 100vw;
      height: 100vh;
      // border: 5px solid red;
      position: absolute; 
      @include trans();

      .iphone14Pro {
        width: 65%;
        position: absolute;
        left: 50%;
        // top:80%;
        top: 104%;
        transform: translate(-50%, -50%);
        transition: 2s cubic-bezier(1, 0, 0, 1);
        opacity: 0.7;
      }
      .compress-Img {
        width: 46%;
        position: absolute;
        left: 50%;
        // top:80%;
        top: 104%;
        transform: translate(-50%, -50%);
        transform-origin: bottom;
        transition: 2s cubic-bezier(1, 0, 0, 1);
        opacity: 0.3;
      }
      .triangle-logo {
        width: 3.5%;
        position: absolute;
        left: 50%;
        top: 96.5%;
        transform: translate(-50%, -50%);
        transition: 2s cubic-bezier(1, 0, 0, 1);
        opacity: 0.3;
      }
    }
  }

  .navigate-page-main-container {
    width: 100%;
    height: 100vh;
    // border:2px solid red;
    position: absolute;
    top: 0%;
    left: 0%;

    .navigate-page-main {
      width: 100%;
      height: 100vh;
      // border: 2px solid yellow;
      position: relative;
      top: 0%;
      left: 0%;

      .office-bg {
        width: 100%;
        height: 100vh;
        position: absolute;
        opacity: 0;
        @include trans();
      }

      .navigate-txt {
        width: 50%;
        position: absolute;
        bottom: 23%;
        right: 2%;
        opacity: 0;
        @include trans();
      }
      .laptop-images-container {
        width: 60%;
        position: absolute;
        bottom: 10%;
        left: 5%;
        // border:2px solid red;

        .laptop-img {
          width: 100%;
          opacity: 0;
          @include trans();
        }

        .lapImg1 {
          width: 12%;
          position: absolute;
          bottom: 15%;
          right: 35%;
          opacity: 0;
          @include trans();
          z-index: 1;
        }
        .lapImg2 {
          width: 10%;
          position: absolute;
          top: 15%;
          right: 44%;
          opacity: 0;
          @include trans();
          z-index: 2;
        }
        .lapImg3 {
          width: 9%;
          position: absolute;
          top: 17%;
          left: 5.5%;
          opacity: 0;
          @include trans();
          z-index: 3;
        }
        .lapImg4 {
          width: 12%;
          position: absolute;
          bottom: 17%;
          left: 3%;
          opacity: 0;
          @include trans();
          z-index: 4;
        }
        .green-dashed1-container {
          width: 0%;
          position: absolute;
          top: 2.5%;
          left: 9%;
          overflow: hidden;
          opacity: 0;
          // border:2px solid orange;

          @keyframes lineAnim1 {
            25% {
              width: 25%;
            }
            50% {
              width: 50%;
            }
            75% {
              width: 75%;
            }
            100% {
              width: 100%;
            }
          }

          .green-dashed1 {
            width: 32vw;
            //  animation:lineAnim1 2.5s  cubic-bezier(1, 0, 0, 1) forwards;
          }
        }
        .green-dashed2-container {
          width: 0%;
          position: absolute;
          bottom: -7%;
          left: 10%;
          overflow: hidden;
          opacity: 0;
          // border:2px solid orange;

          .green-dashed2 {
            width: 32vw;
          }
        }
        .green-dashed3-container {
          height: 0vw;
          position: absolute;
          top: 5%;
          left: 55.5%;
          overflow: hidden;
          transform-origin: top;
          opacity: 0;
          // border:2px solid orange;

          @keyframes lineAnim3 {
            25% {
              height: 3.5vw;
            }
            50% {
              height: 7vw;
            }
            75% {
              height: 10.5vw;
            }
            100% {
              height: 14vw;
            }
          }

          .green-dashed3 {
            height: 14vw;
          }
        }
      }
    }
  }
}




//--------------------------  max-width: 1000px  --------------------------------------

@media only screen and (max-width: 1000px) {
  .main-container-optimize {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    transition: 3s ease-in-out;
  
    .optimize-block-outer-layer-container {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      // border:5px solid red;
      transition: 1.5s cubic-bezier(1, 0, 0, 1);
  
      .bg-img-optimize {
        transition: 2s ease-in-out;
        opacity: 0;
      }
  
      .optimize-block {
        width: 65%;
        height: 50vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        @include trans();
        // border: 2px solid blue;
  
        .small-green-block-container {
          width: 100vw;
          height: 100vh;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          // border:5px solid red;
          @include trans();
  
          .small-green-blocks {
            width: 1.35vw;
            // position: absolute;
            margin-top: -25vw;
            z-index: 2;
            opacity: 0;
            @include trans();
          }
        }
  
        .with-txt {
          width: 11.5%;
          position: absolute;
          top: 71%;
          left: 19%;
          opacity: 0;
          z-index: 1;
          @include trans();
        }
        .green-shadow {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          @include trans();
        }
  
        .optimize-time-txt {
          width: 65%;
          position: absolute;
          opacity: 0;
          @include trans();
          transform: translate(1050%, 600%) scale(26);
        }
        .green-fudge {
          width: 2.5%;
          position: absolute;
          top: 39.25%;
          left: 49%;
          opacity: 0;
          transform: rotate(-15deg);
          @include trans();
          z-index: 2;
        }
        .vector1 {
          width: 20%;
          position: absolute;
          top: 84%;
          left: 20%;
          @include trans(0.5s);
          opacity: 0;
        }
        .vector2 {
          width: 16%;
          position: absolute;
          top: 30%;
          right: 2%;
          @include trans(0.5s);
          opacity: 0;
        }
        .vector3 {
          width: 7%;
          position: absolute;
          top: 77%;
          left: 53%;
          @include trans(0.5s);
          opacity: 0;
        }
        .left-photo {
          width: 35%;
          position: absolute;
          top: 750%;
          left: 20%;
          opacity: 0;
          transition: 2.5s ease-in-out;
          transform: scale(7.2);
          // border:1px solid red;
        }
        .middle-photo {
          width: 32%;
          position: absolute;
          top: 550%;
          left: -40%;
          opacity: 0;
          transition: 2.5s ease-in-out;
          transform: scale(7.8);
          z-index: 2;
          // border:1px solid red;
        }
        .right-photo {
          width: 32%;
          position: absolute;
          top: 535%;
          right: -150%;
          opacity: 0;
          transition: 2.5s ease-in-out;
          transform: scale(7.2);
          // border:1px solid red;
        }
      }
    }
    .people-background-container {
      width: 100%;
      height: 100vh;
      position: absolute;
      opacity: 0;
      transition: 1s ease-in-out;
      // border: 2px solid yellow;
  
      .bg-People-Img {
        width: 100%;
        height: 100vh;
        position: absolute;
        // border: 2px solid green;
      }
  
      .benefit-txt {
        width: 20%;
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 2s cubic-bezier(1, 0, 0, 1);
        opacity: 0;
      }
      .green-rect {
        width: 21%;
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 2s cubic-bezier(1, 0, 0, 1);
        opacity: 0;
      }
      .iphone-container {
        width: 100vw;
        height: 100vh;
        // border: 5px solid red;
        position: absolute;
        @include trans();
  
        .iphone14Pro {
          width: 65%;
          position: absolute;
          left: 50%;
          // top:80%;
          top: 104%;
          transform: translate(-50%, -50%);
          transition: 2s cubic-bezier(1, 0, 0, 1);
          opacity: 0.7;
        }
        .compress-Img {
          width: 46%;
          position: absolute;
          left: 50%;
          // top:80%;
          top: 104%;
          transform: translate(-50%, -50%);
          transform-origin: bottom;
          transition: 2s cubic-bezier(1, 0, 0, 1);
          opacity: 0.3;
        }
        .triangle-logo {
          width: 3.5%;
          position: absolute;
          left: 50%;
          top: 96.5%;
          transform: translate(-50%, -50%);
          transition: 2s cubic-bezier(1, 0, 0, 1);
          opacity: 0.3;
        }
      }
    }
  
    .navigate-page-main-container {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0%;
      left: 0%;
      // border:2px solid red;
  
      .navigate-page-main {
        width: 100%;
        height: 100vh;
        position: relative;
        top: 0%;
        left: 0%;
        // border: 2px solid yellow;
  
        .office-bg {
          width: 100%;
          height: 100vh;
          position: absolute;
          opacity: 0;
          @include trans();
        }
  
        .navigate-txt {
          width: 50%;
          position: absolute;
          bottom: 23%;
          right: 2%;
          opacity: 0;
          @include trans();
        }
        .laptop-images-container {
          width: 54%;
          position: absolute;
          bottom: 10%;
          left: 5%;
          // border:2px solid red;
  
          .laptop-img {
            width: 100%;
            opacity: 0;
            @include trans();
          }
  
          .lapImg1 {
            width: 12%;
            position: absolute;
            bottom: 15%;
            right: 35%;
            opacity: 0;
            @include trans();
            z-index: 1;
          }
          .lapImg2 {
            width: 10%;
            position: absolute;
            top: 15%;
            right: 44%;
            opacity: 0;
            @include trans();
            z-index: 2;
          }
          .lapImg3 {
            width: 9%;
            position: absolute;
            top: 17%;
            left: 5.5%;
            opacity: 0;
            @include trans();
            z-index: 3;
          }
          .lapImg4 {
            width: 12%;
            position: absolute;
            bottom: 17%;
            left: 3%;
            opacity: 0;
            @include trans();
            z-index: 4;
          }
          .green-dashed1-container {
            width: 0%;
            position: absolute;
            top: 2.5%;
            left: 9%;
            overflow: hidden;
            opacity: 0;
            // border:2px solid orange;
  
            @keyframes lineAnim1 {
              25% {
                width: 25%;
              }
              50% {
                width: 50%;
              }
              75% {
                width: 75%;
              }
              100% {
                width: 100%;
              }
            }
  
            .green-dashed1 {
              width: 32vw;
              //  animation:lineAnim1 2.5s  cubic-bezier(1, 0, 0, 1) forwards;
            }
          }
          .green-dashed2-container {
            width: 0%;
            position: absolute;
            bottom: -7%;
            left: 10%;
            overflow: hidden;
            opacity: 0;
            // border:2px solid orange;
  
            .green-dashed2 {
              width: 32vw;
            }
          }
          .green-dashed3-container {
            height: 0vw;
            position: absolute;
            top: 5%;
            left: 55.5%;
            // border:2px solid orange;
            overflow: hidden;
            transform-origin: top;
            opacity: 0;
  
            @keyframes lineAnim3 {
              25% {
                height: 3.5vw;
              }
              50% {
                height: 7vw;
              }
              75% {
                height: 10.5vw;
              }
              100% {
                height: 14vw;
              }
            }
  
            .green-dashed3 {
              height: 14vw;
            }
          }
        }
      }
    }
  }
  
}
