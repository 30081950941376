.overcome-limitations-comp-main-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    transition: 1.8s ease-out;
    z-index: 2;
    overflow: hidden;
    opacity: 0;

    .overcome-limitations-comp-main {
        position: relative;
        width: 100%;
        height: 100%;
        // background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;


        .vector-img-one,
        .vector-img-two {
            position: absolute;
            width: 65vw;
            object-fit: contain;
            transition: 1.6s cubic-bezier(.25, .08, .21, 1.05);

        }

        .vector-img-one {
            top: -48.5vw
        }

        .vector-img-two {
            bottom: -45vw
        }

        .overcome-heading {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            transition: 1.6s cubic-bezier(.25, .08, .02, .98);
            transform: scale(55) translate(19.5vw, 1.2vw);


            .top-text {
                width: 100%;
                color: #fff;
                font-size: 4vw;
                font-weight: 600;
                text-shadow: 0 0.2vw 2px #00000098;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                line-height: 1.2;
                letter-spacing: -0.25vw;

                .span1 {
                    color: #55da57;

                    .sub-span {
                        color: #69FF6A;

                    }

                }

                .span2 {
                    font-size: 2vw;
                    background-color: #fff;
                    color: #000;
                    padding: 0.25em 0;
                    border-radius: 0 20px 0 0;
                    letter-spacing: 0vw;

                }

                // .span3 {}
            }

            .bottom-text {
                position: relative;
                width: 100%;
                color: #fff;
                font-size: 3.8vw;
                font-weight: 600;
                text-shadow: 0 0.2vw 2px #00000098;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                // border: 1px solid #fff;
                line-height: 0.6;
                letter-spacing: -0.25vw;


                .span1 {
                    position: absolute;
                    left: 1%;
                    top: -28%;
                    width: 4.2vw;
                    height: 4.2vw;
                    font-size: 2.6vw;
                    font-weight: 600;
                    color: #fff;
                    font-style: italic;
                    border: 2px solid #fff;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    padding: 0 0.5% 0% 0;
                    text-transform: uppercase;
                    letter-spacing: 0vw;



                }


                .span3 {
                    color: #55da57;

                    .sub-span {
                        color: #69FF6A;

                    }

                }

                .span2 {
                    color: #E2E2E2;

                }
            }
        }



    }
}