.first-comp-main-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    transition: 1.8s ease-out;
    z-index: 2;
    overflow: hidden;
    // transform: translateY(100vh);

    .first-comp-main {
        position: relative;
        width: 100%;
        height: 100%;
        // background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        // .vector-one-img,
        .vector-two-img,
        .vector-three-img,
        .vector-four-img,
        .vector-five-img {
            position: absolute;
            transition: 1.6s cubic-bezier(.23, .16, 0, .99);
            object-fit: contain;
            z-index: 0;
            will-change: transform;

        }

        .vector-one-img-div {
            position: absolute;
            width: 20vw;
            width: 20vw;
            transform: translate(-16vw, -10vh) rotate(40deg);
            z-index: 2;
            transition: 1.6s cubic-bezier(.23, .16, 0, .99);
            will-change: transform;


            .vector-one-img {
                width: 100%;
                object-fit: contain;
                mix-blend-mode: hard-light;
            }
        }

        .vector-two-img {
            width: 10vw;
            transform: translate(-30vw, 18vh) rotate(-100deg);
            filter: blur(2px);
            opacity: 0.6;
        }

        .vector-three-img {
            width: 20vw;
            transform: translate(-45vw, 36vh) rotate(-125deg);
        }

        .vector-four-img {
            width: 18vw;
            transform: translate(18vw, 48vh) rotate(-20deg);
            filter: blur(3px);

        }

        .vector-five-img {
            width: 28vw;
            transform: translate(40vw, -10vh) rotate(80deg);
            // opacity: 0;
            mix-blend-mode: overlay;
            // background-color: #000


        }


        .logo-container {
            position: absolute;
            width: 30vw;
            height: 30vw;
            // border: 1px solid #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 1.8s cubic-bezier(.23, .16, 0, .99) 0s;
            will-change: transform;

            .vector-six-img {
                position: absolute;
                width: 28vw;
                object-fit: contain;
                transform: rotate(-80deg);
                transition: 2.2s ease-out;
                will-change: transform;
            }

            .logo-img {
                position: absolute;
                width: 15vw;
                transition: 2.2s ease-out;
                object-fit: contain;
                z-index: 1;
                margin-top: -3vh;
                margin-left: 0.5vw;
                will-change: transform;
                transform: scale(0.6);
            }
        }

        .first-comp-content-div {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 2.2s ease-out;


            .top-text {
                position: absolute;
                margin-top: -45vh;
                color: #fff;
                font-size: 1.8vw;
                font-weight: 300;
                font-style: italic;
                transition: 1.8s cubic-bezier(.23, .16, 0, .99) 0s;
                opacity: 1;

                b {
                    color: #55da57;
                }
            }

            .agriculture-text {
                position: absolute;
                color: #fff;
                margin-left: -48vw;
                margin-top: -5vh;
                font-size: 3.5vw;
                font-weight: 600;
                text-transform: uppercase;
                transition: 1.8s cubic-bezier(.23, .16, 0, .99) 0s;
            }

            .agriculture-mirror-text {
                position: absolute;
                margin-left: -48vw;
                margin-top: 4vh;
                font-size: 3.5vw;
                font-weight: 600;
                text-transform: uppercase;
                background: linear-gradient(180deg, #fff -20%, rgba(255, 255, 255, 0.00) 80%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                transform: scaleY(-1);
                transition: 1.8s cubic-bezier(.23, .16, 0, .99) 0s;
                opacity: 0.5;
            }

            .food-and-technology-text {
                position: absolute;
                color: #fff;
                font-style: italic;
                margin-left: 45vw;
                font-size: 3vw;
                font-weight: 300;
                line-height: 1;
                text-transform: uppercase;
                transition: 1.6s ease-out;
                transform: translateY(50vh);
                opacity: 0;

                .bold-text-1 {
                    color: #fff;
                    font-style: normal;
                    background-color: #00A502;
                    border-radius: 100px;
                    padding: 0 0.2em;
                    display: inline-block;
                    text-shadow: 0vw 0.1vw 2px #00000075;
                    margin-right: 1vw;

                }

                .bold-text-2 {
                    background: linear-gradient(173deg, #FFF 52.55%, rgba(183, 183, 183, 0.33) 106.58%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

            }

        }

    }
}