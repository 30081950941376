.comptwo-section {
  position: absolute;
  width: 100%;
  height: 100vh;
  transform: translate(100%, -35%);
  transition: 3s cubic-bezier(0.91, 0.02, 0, 0.97);

  .comptwo-container {
    position: relative;
    width: 100%;
    height: 100vh;
    .comptwo-s1 {
      .comptwo-s1-left {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: 3s cubic-bezier(0.91, 0.02, 0, 0.97);
        img {
          position: absolute;
          width: 10%;
        }
        .circular-elm {
          top: -3%;
          left: -1.5%;
          width: 8%;
        }
        .comptwo-text {
          width: 25%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: 3s cubic-bezier(0.91, 0.02, 0, 0.97);
        }
        .hd-circular {
          top: 47%;
          width: 12%;
          left: 50%;
          transition: 3s cubic-bezier(0.91, 0.02, 0, 0.97);
          opacity: 0;
        }
        .circular-elm-big {
          bottom: -25%;
          right: -10%;
          width: 30%;
          transition: 3s cubic-bezier(0.91, 0.02, 0, 0.97);
        }
      }
      .comptwo-s1-right {
        position: absolute;
        width: 50%;
        height: 100%;
        right: 0;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 3s cubic-bezier(0.91, 0.02, 0, 0.97);
        .arrow-left {
          width: 50%;
          height: 100%;
          position: relative;
          .sponsor-arrow {
            position: absolute;
            transition: 3s cubic-bezier(0.91, 0.02, 0, 0.97);
            width: 30%;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .text-right {
          width: 50%;
          height: 100%;
          transform: translateX(100%);
          transition: 3s cubic-bezier(0.91, 0.02, 0, 0.97);
          display: flex;
          flex-direction: column;
          p {
            font-size: 2vw;
            position: absolute;
            font-weight: 500;
          }
          .comptwo-p1 {
            top: 24vh;
          }
          .comptwo-p2 {
            top: 49vh;
          }
          .comptwo-p3 {
            top: 74vh;
          }
        }
      }
    }
  }
}
