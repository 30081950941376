.phone-rotation-comp-main-container {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    @keyframes rotateScreen-keyframe {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: rotate(90deg);
        }

        100% {
            transform: rotate(90deg);
        }
    }

    .phone {
        height: 17vw;
        width: 32vw;
        background-color: white;
        border-radius: 2vw;
        animation: rotateScreen-keyframe 1.8s ease-in-out infinite alternate;
        /* display: none; */
        display: flex;
        justify-content: center;
        // flex-direction: column;
        align-items: center;
        padding-right: 0.8vw;


        .mock-up {
            height: 100%;
            width: 100%;
            border: 0.6vw solid white;
            background-color: rgba(0, 0, 0, 1);
            border-radius: 2vw;
        }

        .small-circle {
            width: 0.4vw;
            height: 3vw;
            border-radius: 50px;
            background-color: black;
        }
    }

    .message1 {
        color: white;
        font-size: 4vw;
        font-weight: 300;
        margin-top: 8vw;
        /* display: none; */
    }

    .message2 {
        color: white;
        font-size: 5.5vw;
        font-weight: 300;
        margin-bottom: 8vw;
        /* display: none; */
    }
}