.bottom-navbar-main-container {
    position: absolute;
    width: 50%;
    height: 4vh;
    display: flex;
    transition: 1.8s ease-in;
    // border: 1px solid #fff;
    // overflow: hidden;
    // opacity: 0;
    bottom: 0%;
    margin-bottom: 2.5vw;
    z-index: 1;

    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 0;
        filter: blur(8px);
    }

    .bottom-navbar-main {
        position: relative;
        width: 100%;
        height: 100%;
        // background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;


        .span-img {
            position: absolute;
            bottom: 100%;
            left: -1%;
            width: 1.5vw;
            height: 1.5vw;
            // border: 1px solid #fff;
            background-position: center;
            background-size: contain;
            transition: 1.2s ease-out;

        }

        .hr-line {
            position: absolute;
            bottom: 100%;
            width: 100%;
            height: 1px;
            background-color: #0DF40F;
            clip: rect(0px, 0, 1px, 0px);
            transition: 1.2s ease-out;

        }

        .name-text {
            position: relative;
            color: #fff;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5vw;
            font-size: 1vw;
            text-transform: uppercase;
            transition: 1.2s ease-out;

        }


    }


}