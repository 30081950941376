.event-highlight-main-container{
    position: relative;
    width: 100%;
    height: 100%;
    // background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
        width: 60%;
        object-fit: contain;
        border-radius: 2vw;
        border: 1px solid #ffffff3f;
    }

    .cross-icon{
        position: absolute;
        color: #ffffff59;
        font-size: 1.4vw;
        font-weight: 200;
        margin-top: -56vh;
        margin-right: -56vw;
        cursor: pointer;
        transition: 0.1s ease-in-out;

        &:hover{
            color: #fff;
        }
    }

}