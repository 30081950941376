.navbar-main {
  position:absolute;
  // position: sticky;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  // background-color: black;
  z-index: 4;

  .left-nav {
    width: 40%;
    img {
      width: 32%;
      cursor: pointer;
    }
  }
  .right-nav {
    width: 50%;
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4vw;

      a {
        font-size: 1.2vw;
        font-weight: 500;
        color: #929292;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          color: white;
        }
      }
      div {
        font-size: 1.2vw;
        font-weight: 500;
        // color: #535454;
        color: #929292;
        text-decoration: none;
        &:hover {
          color: white;
          cursor: pointer;
        }
      }
    }
  }
}
