.bant-process-comp-main-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1.8s ease-in;
    overflow: hidden;
    opacity: 0;
    // z-index: 10;

    .bg-color-div {
        position: absolute;
        width: 150vw;
        height: 150vw;
        background-color: #333;
        transition: 1.2s ease-out;
        border-radius: 50%;
        transform: scale(0);
    }

    .vector-div-one,
    .vector-div-two {
        position: absolute;
        width: 60vw;
        height: 60vw;
        transition: 1.2s ease-out;
        opacity: 0;
        will-change: transform;
        display: flex;
        // border: 1px solid #000;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        will-change: transform;


    }


    .vector-div-one {
        top: -42vw;
        background: linear-gradient(180deg, #55da57 0%, rgba(48, 255, 50, 0.00) 100%);

        .vector-img-one {
            width: 45%;
            height: 45%;
            object-fit: contain;
            will-change: transform;
            transform: rotate(-30deg);
            transition: 1.2s ease-out;
            margin-top: 15vw;

        }
    }

    .vector-div-two {
        bottom: -42vw;
        background: linear-gradient(360deg, #55da57 0%, rgba(48, 255, 50, 0.00) 100%);

        .vector-img-two {
            width: 45%;
            height: 45%;
            object-fit: contain;
            will-change: transform;
            transform: rotate(-40deg);
            transition: 1.2s ease-out;
            margin-bottom: 15vw;

        }
    }

    .bant-process-comp-main {
        position: relative;
        width: 100%;
        height: 100%;
        // background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        .vector-one-img {
            position: absolute;
            width: 5vw;
            z-index: 1;
        }

        .potential-participant-profiling-text-div {
            width: 16vw;
            // height: 8vw;
            margin-top: 10vh;
            margin-left: 2vw;
            transform: translate(10vw, 10vh) scaleY(-1);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: 1.6s ease-in-out;

            // border: 1px solid #fff;

            .potential-participant-profiling-text-img {
                width: 100%;
                object-fit: contain;

            }


            .span1 {
                transition: 1.6s ease-in-out;
                transform: translateY(3.8vh);

                .sub-span {
                    background: linear-gradient(110deg, #000 -20.13%, rgba(127, 127, 127, 0.60) 85.47%);
                    border-radius: 50px 0 0 50px;
                    padding: 0em 0.2em;
                    font-size: 1.6vw;

                }
            }

            .span2 {
                color: #55da57;
                font-size: 2.2vw;
                transition: 1.6s ease-in-out;
                transform: translateY(0vh);

            }

            .span3 {
                color: #ffffff71;
                font-size: 2vw;
                font-weight: 200;
                transition: 1.6s ease-in-out;
                transform: translateY(-3.8vh);

            }
        }

        .bant-heading-div {
            position: absolute;
            display: flex;
            justify-content: center;
            opacity: 0;
            transition: 1.6s cubic-bezier(.23, .16, 0, .99);
            margin-top: 4vw;
            transform: translate(40vw);
            will-change: transform;


            // align-items: center;

            // .table-menu-div {
            //     position: absolute;
            //     bottom: 100%;
            //     // left: 0;
            //     width: 60vw;
            //     height: 4vw;
            //     // border: 1px solid #fff;
            //     display: flex;
            //     justify-content: space-between;
            //     align-items: center;
            //     opacity: 0;
            //     transition: 1.6s cubic-bezier(.23, .16, 0, .99);
            //     margin-left: -2vw;


            //     .list-name {
            //         font-size: 1.6vw;
            //         color: #fff;
            //         display: flex;
            //         justify-content: center;
            //         align-items: center;
            //         gap: 1vw;

            //         .img-span {
            //             width: 1.2vw;
            //             height: 1.2vw;
            //             // border: 1px solid #fff;
            //             background-position: center;
            //             background-size: contain;
            //             transition: 0.2s ease-out;
            //         }
            //     }
            // }

            .content-container {
                position: relative;
                width: 70vw;
                height: 12vw;
                transition: 1.2s ease-out;
                // border: 1px solid #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 1.8vw;
                display: flex;
                justify-content: center;
                align-items: center;

                .table-menu-div {
                    position: absolute;
                    // width: 76vw;
                    width: 100%;
                    bottom: 100%;
                    height: 4vw;
                    // border: 1px solid #fff;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    opacity: 0;
                    transition: 1.6s cubic-bezier(.23, .16, 0, .99);
                    // border: 1px solid #fff;

                    .list-heading {
                        width: 90%;
                        object-fit: contain;
                        margin-left: -0.8vw;
                    }

                    // .list-name {
                    //     font-size: 1.6vw;
                    //     color: #fff;
                    //     display: flex;
                    //     justify-content: center;
                    //     align-items: center;
                    //     gap: 1vw;

                    //     .img-span {
                    //         width: 1.2vw;
                    //         height: 1.2vw;
                    //         // border: 1px solid #fff;
                    //         background-position: center;
                    //         background-size: contain;
                    //         transition: 0.2s ease-out;
                    //     }
                    // }
                }

                .inner-div {
                    width: 100%;
                    height: 100%;
                    // background-color: #000;
                    transition: 1.2s ease-out;
                    will-change: transform;
                    overflow: hidden;

                    .bant-list-img {
                        min-width: 100%;
                        height: 100%;
                        object-fit: contain;
                        transition: 2.6s cubic-bezier(.23, .16, 0, .99);
                        transform: scaleY(-0.6);
                    }

                }


            }

            .left-name {
                font-size: 4.5vw;
                color: #fff;
                font-weight: 600;
                position: relative;
                text-shadow: 0 0.15vw 2px #000000b7;
                transition: 1.2s ease-out;
                will-change: transform;

                .process-span {
                    position: absolute;
                    // left: 2%;
                    margin-left: -8.8vw;
                    top: 4.2vw;
                    font-size: 2.4vw;
                    font-weight: 400;
                    text-transform: uppercase;
                    color: #fff;
                    transition: 1.6s cubic-bezier(.23, .16, 0, .99);
                    transform: translate(-40vw);
                    will-change: transform;

                    .span1 {
                        transition: 0.6s ease-in-out;
                        will-change: transform;
                        margin-left: 6vw;
                    }

                    .span2 {
                        transition: 0.6s ease-in-out;
                        will-change: transform;

                    }

                    .progress-sub-span {
                        position: absolute;
                        margin-top: -3vw;
                        margin-left: 7.5vw;
                        width: 90vw;
                        display: flex;
                        justify-content: center;
                        gap: 14vw;
                        align-items: center;
                        // border: 1px solid black;
                        transition: 0.6s ease-in-out;
                        opacity: 0;
                    }

                }
            }

            .right-name {
                font-size: 4.5vw;
                // color: #ffffffa2;
                color: #000;
                font-weight: 600;
                text-shadow: 0 0.1vw 2px #0000007c;
                transition: 1.2s ease-out;
                will-change: transform;
                margin-left: 2vw;


            }


        }
    }


}