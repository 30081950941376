.footer-section {
  width: 100%;
  height: 40vh;
  background-color: #000000;
  color: white;
  overflow: hidden;
  padding-top: 2vw;

  hr {
    opacity: 0.2;
    // background-color: red;
  }

  .footer-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0vw 8vw;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-footer {
      display: flex;
      gap: 1vw;
      align-items: center;
      width: 44%;

      .location-icon {
        width: 11%;
      }

      .footer-address {
        line-height: 1.7vw;
        font-size: 1.05vw;
      }
    }

    .right-footer {
      width: 45%;
      display: flex;
      justify-content: space-between;

      .left-ul,
      .right-ul {
        width: 50%;
        height: 100%;

        li {
          list-style: none;
          display: flex;
          gap: 1vw;
          align-items: center;
          margin-top: 0.5vw;

          img {
            width: 2vw;
            height: 2vw;
            object-fit: contain;
          }

          a {
            text-decoration: none;
            color: white;
            font-size: 1.1vw;
            line-height: 2vw;

            &:hover {
              color: #69ff6a;
            }
          }
        }
      }
    }

    .join-us-btn {
      // position: absolute;
      width: 3vw;
      cursor: pointer;

      img{
        width: 100%;
      }
    }
  }
}