.cpop-up {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.758);
  z-index: 12;
  .cpopup-wrapper {
    width: 33vw;
    height: 21vw;
    position: relative;
    color: white;
    background-color: #292929;
    border: 1px solid #3be95f;
    border-radius: 1.2vw;
    padding: 1vw 2vw;
    .cpop-close {
      position: absolute;
      top: 1%;
      right: 4%;
      color: #3be95f;
      cursor: pointer;
      font-size: 2.2vw;
      font-weight: 800;
    }

    h2 {
      font-weight: 500;
      font-size: 1.5vw;
      padding: 0.5vw 0vw;
    }
    .cp-email {
      padding: 0.5vw 0vw;
      .cp-para1 {
        font-size: 1.3vw;
      }
      .cp-para2 {
        font-size: 1.2vw;
        color: #3be95f;
        padding: 0.5vw 0vw;
        cursor: pointer;
      }
    }
    .cp-address {
      .cpd-heading {
        font-size: 1.3vw;
      }
      .cp-full-details {
        padding: 0.5vw 0vw;
        font-weight: 300;
        font-size: 1.1vw;
        line-height: 1.6vw;
        color: #3be95f;
      }
    }
    .social-links {
      padding: 0.5vw 0vw;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 2vw;
      // justify-content: center;
      img {
        width: 9%;
        cursor: pointer;
      }
    }
  }
}
