.home-page-main-container {
    width: 100%;
    // height: 58vw;
    // height: 100vh;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000;
    overflow: hidden;



    .nav-bar-loader {
        z-index: 12;
        top: 0;
        left: 0;
    }

    .hidden-touch-preventiong-div {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        // background-color: #00000080;
        // background-color: #ffee0096;
        z-index: 15;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .join-us-floating-div {
        position: fixed;
        right: 2.2%;
        top: 80vh;
        width: 3.5vw;
        height: 3vw;
        // background-color: #0DF40F;
        z-index: 11;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 0.5vw;
        font-size: 1vw;
        font-weight: 600;
        line-height: 1vw;
        color: #69FF6A;
        border: 0.15vw solid #69FF6A;
        cursor: pointer;
        transform: scale(0.96);
        transition: 0.1s ease-out;

        &:hover {
            transform: scale(1);

        }
    }

    .iframe-container {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 12;
        display: flex;
        justify-content: center;
        align-items: center;

        .hidden-layer {
            position: absolute;
            width: 100%;
            height: 100%;
            // pointer-events: auto;
            // background-color: #fff;

        }

    }


    .canvas-main-container {
        position: fixed;
        top: 2%;
        left: 2%;
        width: 95vw;
        // height: 10vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border: 1px solid #fff;
        z-index: 5;
        background: none;

        .main-logo-img {
            width: 10%;
            object-fit: contain;
            cursor: pointer;
        }

        .nav-list-top {
            width: 22%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 1.5vw;

            .navigate {
                font-size: 1.2vw;
                font-weight: 400;
                color: #ffffff85;
                cursor: pointer;
                text-transform: capitalize;
                transition: 0.2s ease-in-out;

                &:hover {
                    color: #55da57;
                    font-weight: 500;

                }
            }
        }

        .canvas {
            width: 100% !important;
            height: 100% !important;
            background: none;

        }
    }

    .home-page-main {
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .footer-section-home-page {
            position: absolute;
            height: 10vh;
            width: 100%;
            bottom: 0;
            left: 0;
            // background-color: #fff;
            // border: 1px solid #fff;
            z-index: 10;
            transition: 1.2s ease-in;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10vw;


            .icon-div {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1vw;

                img {
                    width: 2vw;
                    object-fit: contain;
                }

                a {
                    font-size: 1.2vw;
                    color: #fff;
                    font-weight: 500;
                    cursor: pointer;
                    text-decoration: none;

                    &:hover {
                        color: #55da57;

                    }
                }
            }
        }

        .nav-vector-img {
            position: absolute;
            width: 100%;
            bottom: 0;
            z-index: 1;
            transition: 1.6s ease-out;
            opacity: 0;
        }

        .main-bg-img-div {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #000;
            z-index: 0;

            .main-bg-img {
                width: 130%;
                object-fit: contain;
                mix-blend-mode: hard-light;
                opacity: 0.3;
                transition: 2.8s ease-out;
                transform: rotate(-60deg);
                margin-left: -45vw;
                margin-top: -95vh;

            }
        }
    }

}

.loader-container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #000;

    .bg-img-div {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000;
        z-index: 0;

        .bg-img {
            width: 130%;
            object-fit: contain;
            mix-blend-mode: hard-light;
            opacity: 0.3;
            transition: 2.8s ease-out;
            margin-left: -45vw;
            margin-top: -95vh;
            transform: rotate(60deg);
            animation: rotateBG 6s infinite linear;
        }
    }

    .loader-bg {
        position: absolute;
        width: 8vw;
        padding: 5%;
        background-image: linear-gradient(40deg, rgba(30, 182, 0, 0.8) 35%, rgba(0, 0, 0, 0) 50%);
        border-radius: 50%;
        z-index: 0;
        transition: 1.6s;
        animation: rotateLogo 2s infinite linear, glow 2s infinite alternate;

        .loader-div {
            position: absolute;
            background-color: #000;
            width: 12vw;
            height: 12vw;
            margin-top: 1vw;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            .logo {
                width: 100%;
                max-width: 12vw;
            }
        }
    }
}

@keyframes rotateLogo {
    100% {
        transform: rotate(45deg);
    }
}

@keyframes rotateBG {
    100% {
        transform: rotate(90deg);
    }
}

@keyframes glow {
    0% {
        box-shadow: 0 0 10px rgba(13, 244, 15, 0.8);
    }

    100% {
        box-shadow: 0 0 20px rgba(13, 244, 15, 0.8);
    }
}

@media only screen and (max-width: 600px) {
    .home-page-main-container {
        .canvas-main-container {
            width: 25vw !important;
            height: 15vw !important;
        }
    }
}






@media screen and (max-device-width: 950px) {
    .home-page-main-container {
        .join-us-floating-div {
            right: 2.5%;
            top: 68vh;
            width: 5.5vw;
            height: 5vw;
            border-radius: 0.5vw;
            font-size: 1.5vw;
            font-weight: 600;
            line-height: 1.5vw;
            border: 0.2vw solid #69FF6A;

        }
    }

}

//--------------------------  max-width: 600px  --------------------------------------
@media screen and (max-device-width: 600px) {
    .home-page-main-container {
        .join-us-floating-div {
            right: 2.2%;
            top: 68vh;
            width: 6.5vw;
            height: 5vw;
            border-radius: 0.5vw;
            font-size: 1.6vw;
            font-weight: 600;
            line-height: 1.6vw;
            border: 0.3vw solid #69FF6A;

        }
    }

}