.video-card-main-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1.6s cubic-bezier(.23, .16, 0, .99);


    .video-card-main {
        position: relative;
        width: 100%;
        height: 100%;
        background: linear-gradient(149deg, #1D1D1D 27.95%, #4D4D4D 63.83%);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1vw;
        box-shadow: -1px 1px 2px #0df411;

        .video-frame {
            position: absolute;
            width: 4.5vw;
            height: 4.5vw;
            border-radius: 50%;
            // border: 1px solid #fff;
            // background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            box-shadow: 0 0.3vw 4px #000000ab;
            z-index: 1;

            .video-palyer {
                min-width: 105%;
                min-height: 105%;
                border-radius: 50%;
                touch-action: none;
            }
        }


        .frame-one-div {
            margin-top: -90%;
            margin-left: 15%;
            transform: scale(0.9);
        }

        .frame-two-div {
            margin-top: -50%;
            margin-left: 85%;
            transform: scale(0.9);
        }

        .frame-three-div {
            margin-top: 35%;
            margin-left: 65%;
            transform: scale(0.75);
        }

        .frame-four-div {
            margin-top: 85%;
            margin-left: 80%;
            transform: scale(0.5);
        }

        .frame-five-div {
            margin-top: 80%;
            margin-left: 0%;
            transform: scale(0.75);
        }

        .frame-six-div {
            margin-top: 0%;
            margin-left: -10%;
        }

        .frame-seven-div {
            margin-top: 50%;
            margin-left: -75%;
            transform: scale(0.65);
        }

        .frame-eight-div {
            margin-top: -10%;
            margin-left: -70%;
            transform: scale(0.65);
        }

        .hr-dots {
            position: absolute;
            width: 2vw;
            height: 2vw;
            background-color: #666;
            z-index: 0;

            .hr-line {
                position: absolute;
                // width: 8vw;
                border: none;
                border-bottom: 2px solid #C9C9C9;
                opacity: 0.6;
                border-bottom-style: dashed;
                transform-origin: left;
            }
        }




        .hr1 {
            transform: rotate(-70deg);
            width: 6vw;

        }

        .hr2 {
            transform: rotate(-20deg);
            width: 8vw;

        }

        .hr3 {
            transform: rotate(35deg);
            width: 6vw;

        }

        .hr4 {
            transform: rotate(48deg);
            width: 10vw;


        }

        .hr5 {
            transform: rotate(80deg);
            width: 6vw;

        }

        .hr6 {
            transform: rotate(130deg);
            width: 6vw;

        }

        .hr7 {
            transform: rotate(180deg);
            width: 4vw;
        }

    }
}