.strategic-data-driven-main-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    transition: 1.8s cubic-bezier(.25, .08, .02, .98);
    // overflow: hidden;
    opacity: 0;
    // transform: translateY(-100vh);
    // z-index: 2;


    .strategic-data-driven-main {
        position: relative;
        width: 100%;
        height: 100%;
        // background-color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        // animation: colorBlend 0.3s infinite;


        .main-heading-div {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 1.6s cubic-bezier(.25, .0, .21, 1.05);


            .heading-div {
                position: absolute;
                color: #E2E2E2;
                font-size: 2.5vw;
                line-height: 0.9;
                margin-top: -20vh;
                z-index: 3;
                transition: 1.6s cubic-bezier(.25, .0, .21, 1.05);
                transform: translate(100vw);
                width: 40vw;
                text-align: center;

                .top-text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    letter-spacing: -0.045em;

                    // .span1 {
                    //     display: flex;
                    //     flex-direction: column;
                    //     justify-content: center;
                    //     align-items: center;
                    //     font-size: 1vw;
                    //     font-weight: 300;
                    //     line-height: 0.7;
                    //     background-color: #007D01;
                    //     color: #fff;
                    //     padding: 0.6em 0.08em 0.2em 0em;
                    //     border-radius: 0 1vw 0 0;
                    //     text-shadow: 0 0.4vw 5px #00000081;
                    //     letter-spacing: 0.01em;
                    //     margin-bottom: 0.2vw;

                    //     b {
                    //         // font-weight: 500;
                    //         font-size: 1.4vw;

                    //     }
                    // }


                    .span2 {
                        font-weight: 600;
                        text-shadow: 0 0.4vw 5px #00000081;

                    }
                }

                .bottom-text {
                    .span1 {
                        text-shadow: 0 0.4vw 5px #00000081;
                        font-weight: 600;
                        color: #55da57;
                    }

                    .span2 {
                        text-shadow: 0 0.4vw 5px #00000081;
                        font-weight: 600;
                        color: #69FF6A;
                    }

                }

            }


            .overcome-heading {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                transition: 1.6s cubic-bezier(.25, .08, .02, .98);
                transform: scale(0.68);
                margin-top: -25vw;
                margin-left: -0.4vw;
                opacity: 0;


                .top-text {
                    width: 100%;
                    color: #fff;
                    font-size: 4vw;
                    font-weight: 600;
                    text-shadow: 0 0.1vw 2px #00000079;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 1.2;
                    letter-spacing: -0.25vw;

                    .span1 {
                        color: #55da57;

                        .sub-span {
                            color: #fff;

                        }

                    }

                    // .span2 {
                    //     font-size: 2vw;
                    //     background-color: #fff;
                    //     color: #000;
                    //     padding: 0.25em 0;
                    //     border-radius: 0 20px 0 0;
                    //     letter-spacing: 0vw;

                    // }
                    .span2 {
                        color: #fff;
                    }

                    // .span3 {}
                }

                .bottom-text {
                    position: relative;
                    width: 100%;
                    color: #fff;
                    font-size: 3.8vw;
                    font-weight: 600;
                    text-shadow: 0 0.2vw 2px #00000098;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // border: 1px solid #fff;
                    line-height: 0.6;
                    letter-spacing: -0.25vw;


                    // .span1 {
                    //     position: absolute;
                    //     left: 1%;
                    //     top: -24%;
                    //     width: 4.2vw;
                    //     height: 4.2vw;
                    //     font-size: 2.6vw;
                    //     font-weight: 600;
                    //     color: #fff;
                    //     font-style: italic;
                    //     border: 2px solid #fff;
                    //     border-radius: 50%;
                    //     display: flex;
                    //     justify-content: center;
                    //     align-items: center;
                    //     background-size: contain;
                    //     background-position: center;
                    //     background-repeat: no-repeat;
                    //     padding: 0 1.5% 0.4% 0;

                    // }

                    .span3 {
                        color: #55da57;

                        .sub-span {
                            color: #69FF6A;
                        }
                    }

                    .span2 {
                        color: #E2E2E2;
                    }
                }
            }

        }


        .next-content-div {
            position: absolute;
            margin-top: 180vh;
            width: 100%;
            height: 44vh;
            border-radius: 4vw;
            background-color: #111;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 1.6s cubic-bezier(.25, .08, .02, .98);

            .content-text {
                position: absolute;
                z-index: 4;
                color: #fff;
                font-weight: 600;
                // background-color: #000/;
                // text-shadow: 0 0vw 4px #000;
                font-size: 2.5vw;
                text-transform: capitalize;
                text-align: left;
                line-height: 0.9;
                margin-left: -45vw;
                transform: translateY(20vh) scale(0.6);
                transition: 1.6s cubic-bezier(.25, .08, .02, .98);


                span {
                    color: #55da57;
                    font-style: italic;
                    text-shadow: 0 0.1vw 2px #00000091;
                }
            }

            .vector2-img{
                position: absolute;
                width: 40%;
                object-fit: contain;
                right: 0;
                top: -28%;
                
            }
        }


        .carousel-div {
            position: absolute;
            width: 100%;
            height: 20vw;
            bottom: 10%;
            transition: 1.6s cubic-bezier(.25, .08, .02, .98);
            transform: translate(200vh);
            overflow: hidden;
            display: flex;
            opacity: 0;
            // border: 1px solid #fff;

            .slides-div {
                width: 100%;
                height: 100%;
                // background-color: #555;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4%;
                transition: 1.6s cubic-bezier(.25, .08, .02, .98);
                transform: translate(38%);

                .slide-img {
                    min-width: 30%;
                    object-fit: contain;
                }
            }
        }
    }
}