.main-intent-page {
  position: absolute;
  width:100%;
  height:100vh;
  overflow:hidden;
  background: transparent;
  opacity: 0;
  // z-index: -1;
  // transition:1.5s ease-in-out 2.5s;

  .top-div-intent{
    width:100%;
    height:100vh;
    position: absolute;
    
      .green-ball-intent{
        width:15%;
        position: absolute;
        top:-13%;
        left:50%;
        transform:translateX(-50%);
    }
  
    .green-circle1{
      width:90vh;
      position: absolute;
      left:50%;
      top:-68%;
      transform:translateX(-50%);
    }
  
    .black-green{
      width:15vh;
      position: absolute;
      bottom:-12%;
      left:50%;
      transform:translateX(-50%);
      transition:1.8s ease-in-out;
      opacity:0;
      // border:2px solid yellow;
    }
     .linearGrad-line{
      width:10%;
      height:0.5vh;
      position:absolute;
      right:0%;
      top:50%;
      background: linear-gradient(270deg, #0DF40F -10.3%, rgba(13, 244, 15, 0.00) 52.22%);
      transform: translateY(-50%);
      transition: 1.8s ease-in-out ;
      transform-origin: right;
      opacity:0;
      // border:2px solid greenyellow;
     }
     
     
     .smallest-mobile-container{
       width:100%;
       height:100vh;
       position: absolute;
       display:flex;
       justify-content:center ;
       align-items: center;
       
       .on-board-mobile-small{
        width:12vh;
        position: absolute;
        opacity:0;
        transition:1.8s ease-in-out;
        margin-top: 50vh;
        margin-right: 50vh;
        transform: rotate(-50deg);
        // z-index: 2;
       }
     }

    .mobile-container{
      width:33vh;
      position: absolute;
      top:57%;
      left:50%;
      transform: translate(-50%,125%);
      transition: 1.8s ease-in-out;
      transform-origin: bottom;
      // border:2px solid yellow;
      
      .onBoard-mobile{
        width:100%;
      }
      .onBoard-text{
        font-size: 1.5rem;
        position:absolute;
        top:45%;
        left:45%;
      }
      .text-profile-container{
        width:18vh;
        position: absolute;
        top:57%;
        left:25%;
        transform: translateY(-50%);
        transition:1.8s ease-in-out;
        // border:2px solid red;
        .prolife-text{
          width: 100%;
        }
        
      }
    }
   
    .mobile-container2{
      width:40vh;
      position: absolute;
      top:57%;
      left:50%;
      transform: translate(100vw,-50%);
      transition: 1.8s ease-in-out;
      transform-origin: bottom;
      // overflow: hidden;
      // border:2px solid yellow;
      
      .profile-mobile{
        width:100%;
      }
   
    }

    .interest-mobile{
      width:33vh;
      position: absolute;
      top:51%;
      left:50%;
      transform: translate(100vw,-50%);
      transition: 1.8s ease-in-out;
      transform-origin: bottom;
      z-index: 2;
      // border:2px solid yellow;
    }
    .greenStrip-container{
      width:95vh;
       position: absolute;
       top:47%;
       left:56.5%;
      //  left:116.5%;
       z-index: 1;
        transition: 2.5s ease-in-out;
        overflow: hidden;
        // border:2px solid green;

        .green-strip-text{
          width:95vh;
          object-fit: contain;
          transition: 1.8s ease-in-out;
          transform:translateX(58vw);
        }
        .tour-btn{
          width:15vh;
          position: absolute;
          top:26.75vh;
          left:22.5%;
          transition: 1.8s ease-in-out;
          z-index: 3;
          opacity:0
    }
  }
  .intent-mobile{
    width:34vh;
    position: absolute;
    top:56%;
    left:50%;
    transform: translate(100vw,-50%) rotate(15deg);
    transition: 1.8s ease-in-out;
    // transform-origin: bottom;
  }
  }
}
