.calendar-comp-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.4vw;
    position: absolute;
    overflow: hidden;

    @keyframes rotateBorder {
        100% {
            transform: rotate(360deg);
        }
    }

    .after-div {
        position: absolute;
        width: 99%;
        height: 99%;
        background-color: #111;
        border-radius: 0.4vw;
        z-index: 1;
        display: flex;
        padding: 3vw;

    }

    .bottom-div {
        width: 100%;
        height: 100%;

        .react-calendar {
            max-width: 100%;
            font-size: 1.5vw;

            @media only screen and (max-width: 768px) {
                font-size: 1vw;
            }
        }

        .react-calendar__navigation {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2vw;
            padding-left: 5%;
            padding-right: 5%;
            margin-bottom: 5%;
            font-size: 1.5vw;

            @media only screen and (max-width: 768px) {
                font-size: 1vw;
            }
        }

        .react-calendar__navigation button {
            color: white;
            background: none;
            font-size: 1.5vw;
            cursor: pointer;

            @media only screen and (max-width: 768px) {
                font-size: 1vw;
            }
        }

        .react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
            font-size: 2.5vw;
        }

        .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
            font-size: 2.5vw;
        }

        .react-calendar__navigation__arrow.react-calendar__navigation__next-button {
            font-size: 2.5vw;
        }

        .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
            font-size: 2.5vw;
        }

        // .react-calendar--doubleView {
        //     width: 700px;

        // }

        // .react-calendar--doubleView .react-calendar__viewContainer {
        //     display: flex;
        //     margin: -0.5em;
        // }

        // .react-calendar--doubleView .react-calendar__viewContainer>* {
        //     width: 50%;
        //     margin: 0.5em;
        // }

        .react-calendar,
        .react-calendar *,
        .react-calendar *:before,
        .react-calendar *:after {
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            color: white;

        }

        .react-calendar button {
            margin: 0;
            border: 0;
            // border: 1px solid #fff;
        }

        // .react-calendar button:enabled:hover {
        //     cursor: pointer;
        // }

        // .react-calendar__navigation {
        //     display: flex;
        //     height: 44px;
        //     margin-bottom: 1em;
        // }

        // .react-calendar__navigation button {
        //     min-width: 20%;

        //     /* background: none; */
        //     // background-color: #fff;
        // }

        //   .react-calendar__navigation button:enabled:hover,
        //   .react-calendar__navigation button:enabled:focus {
        //     background-color: red;
        //   }
        .react-calendar__navigation button[disabled] {
            background-color: #888;
        }

        .react-calendar__month-view__weekdays {
            // day name row
            text-align: center;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 1.25vw;
            text-decoration: none;
        }

        .react-calendar__month-view__days__day--neighboringMonth {
            color: #fff;
            opacity: 0.5;
        }

        .react-calendar__tile {
            max-width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 1vw;
            background: none;
            font-size: 1vw;

            @media only screen and (max-width: 768px) {
                font-size: 1vw;
            }
        }

        .react-calendar__tile:enabled:hover,
        .react-calendar__tile:enabled:focus {
            // background-color: #e6e6e6;
            background-color: #555;

        }

        .react-calendar__tile--now {
            /* background: #ffff76; */
            background-color: #4d4d4d;
        }

        .react-calendar__tile--now:enabled:hover,
        .react-calendar__tile--now:enabled:focus {
            background: #000000;
            color: white;
        }

        .react-calendar__tile--hasActive {
            background: #ffffff;
            color: white;
        }

        .react-calendar__tile--hasActive:enabled:hover,
        .react-calendar__tile--hasActive:enabled:focus {
            background: #a9ffb3;
            color: white;
        }

        .react-calendar__tile--active {
            background: #848484;
            color: white;
        }

        .react-calendar__tile--active:enabled:hover,
        .react-calendar__tile--active:enabled:focus {
            background: #777777;
            color: white;
        }

        .react-calendar--selectRange .react-calendar__tile--hover {
            background-color: #e6e6e6;
        }

        .react-calendar .highlighted {
            background-color: green;
        }

    }

    .before-div {
        position: absolute;
        width: 100vw;
        height: 100vh;
        background-image: linear-gradient(40deg, #55da57 35.56%, rgba(0, 0, 0, 0) 50.57%);
        border-radius: 0.4vw;
        z-index: 0;
        transition: 1.6s;
    }

}



//-------------------  max-width: 480px  ------------------------
// @media only screen and (max-width: 480px) {
//     .calendar-comp-main-container {
//         border-radius: 2vw;

//         .after-div {
//             top: 0.5%;
//             left: 0.5%;
//             width: 99%;
//             height: 99%;
//             border-radius: 2vw;

//             .top-div {
//                 .month-and-year-heading {
//                     font-size: 3.2vw;
//                     font-weight: 400;

//                 }

//             }


//             .bottom-div {

//                 .react-calendar__navigation button {
//                     font-size: 2vw;
//                     margin-top: 8px;
//                 }

//                 .react-calendar__month-view__weekdays {
//                     font-size: 2.1vw;

//                 }

//                 .react-calendar__month-view__weekdays__weekday {
//                     padding: 1vw;
//                 }

//                 .react-calendar__month-view__weekNumbers .react-calendar__tile {
//                     font-size: 1vw;
//                 }

//                 .react-calendar__year-view .react-calendar__tile,
//                 .react-calendar__decade-view .react-calendar__tile,
//                 .react-calendar__century-view .react-calendar__tile {

//                     //all year and months
//                     padding: 1vw 0.5vw;
//                 }

//                 .react-calendar__tile {
//                     padding: 0.5vw;
//                 }

//             }

//         }

//         .before-div {
//             width: 120vw;
//             height: 60vh;
//             background-image: linear-gradient(40deg, #55da57 35.56%, rgba(0, 0, 0, 0) 50.57%);
//             border-radius: 2vw;
//         }

//     }

// }