.scrolling-para {
    width: 50%;
    height: 35%;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 10%;
    // overflow: hidden;
    // background-color: #888;
    padding: 0% 5% 0% 15%;
    color: #fff;

    // .scrollbar-div {
    //     position: absolute;
    //     top: 10%;
    //     right: 5%;
    //     height: 80%;
    //     width: 0.5%;
    //     border-radius: 20px;
    //     overflow: hidden;

    //     .scroller {
    //         width: 100%;
    //         height: 100%;
    //         background-color: #fff;
    //         opacity: 0.2;
    //     }

    // }

    .para-slide {
        width: 100%;
        min-height: 70%;
        position: relative;
        top: -70%;
        // background-color: #888;
        transition: all 1s;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10%;
        margin-top: -4.5vh;

        p {
            background: linear-gradient(to bottom right, white 30%, black);
            padding: 1vw;
            font-weight: 300;
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            font-size: 1.2vw;
            // margin-bottom: -10%;
            transition: all 2s;

        }
    }
}