
.new-main-container{
    width:100%;
    height:100vh;
    position:absolute;
    // position:fixed;
    top:0;
    left:0;
    display:flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    transition: 2.5s ease-in-out;
    opacity:0;

    &::-webkit-scrollbar {
        display: none;
      }
      
/* Hide scrollbar for IE, Edge and Firefox */

-ms-overflow-style: none;
/* IE and Edge */
scrollbar-width: none;
/* Firefox */

    // border:2px solid red;

    .fixed-container1{
      width:100%;
      height:100vh;
      position:fixed;
    // border:5px solid yellow;


      .sponsor-bg{
        width: 100%;
        position: absolute;
        background:black;
      }
      .lappy-sponsor{ 
      position: absolute;
      width:175vh;
      bottom:0;
      right:-14%;
      
      }
      .logo-sponsor{
        width:26%;
        position: absolute;
        top:20%;;
        left:7%;
      }
      
      .sponsor-text{
        width:42%;
        position: absolute;
        top:42%;
        left:7%;
      }
      
      .button-box-container{
        width:9%;
        position: absolute;
        top:67%;
        left:7%;
        cursor:pointer;
        // border:2px solid red;

      .button-box{
        // position: absolute;
        width:100%;
      }
      .view-packages-text{
        position: absolute;
        width:13.5vh;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
      }
    }


    }

    .scroll-main{
      width:90%;
      position: relative;
      top: 83vh;
      display:flex;
      flex-direction: column;
      align-items: center;
      background-color: #303030;
      transition: 2s ease-in-out;
      border-radius: 1.5vw;
      overflow: hidden;

    .scroll-container2{
      width:90%;
      overflow: scroll;
      padding-bottom: 15vh;
      display:flex;
      flex-direction: column;
      align-items: center;
      margin-top: 3vw;
      border:1px solid #303030;
      scroll-behavior: smooth;
//       -webkit-box-shadow: inset 0px 10px 5px 0px rgba(0,0,0,0.75);
// -moz-box-shadow: inset 0px 10px 5px 0px rgba(0,0,0,0.75);
// box-shadow: inset 0px 10px 5px 0px rgba(0,0,0,0.75);
      border-radius: 2vw;

        &::-webkit-scrollbar {
            display: none;
          }
          
  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

        .cross{
            position: absolute;
            font-size: 2rem;
            color:rgba(255, 255, 255, 0.274);
            right:2%;
            top:1%;
            cursor:pointer;
            transition: 0.2s ease-in-out;
            opacity:0;

            &:hover{
              color: #fff;
            }
        }
       

      img{
        width:95%;
        margin-top: 2%;
    }
    
    }
    }
}