.scroll-down-comp-main-container {
    position: fixed;
    top: 92vh;
    left: 49vw;
    width: 2vw;
    height: 3.2vw;
    // border: 1px solid #fff;
    z-index: 6;
    mix-blend-mode: screen;


    .img-icon {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

}

//============================================================================================

//-------------------  max-width: 480px  ------------------------
@media only screen and (max-width: 480px) {
    .scroll-down-comp-main-container {
        width: 10vw;
        height: 15vw;
        left: 45vw;

    }
}