.thank-you-main-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    background: black;
    color: white;
    z-index: 1;
    // z-index: 4;
    transition: 1.8s ease-in-out;

    .thank-you-main {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        canvas {
            position: absolute;
            pointer-events: none;
        }

        img {
            width: 20vw;
            object-fit: cover;
            z-index: 0;
        }

        h1 {
            color: #3BE95F;
            font-size: 2.5vw;
            font-weight: 500;
            text-align: center;


            .text-light {
                font-weight: 200;
                color: white;
            }

            .text-white {
                color: white;
            }
        }

        p {
            font-size: 1.2vw;
            font-weight: 200;
            color: white;
            margin-top: 1vw;
        }

    }

    .book-now-btn {
        position: absolute;
        background: none;
        z-index: 2;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14vw;
        height: 3.25vw;
        border-radius: 100px;
        background-color: #000;
        transition: 1.6s ease-out;
        pointer-events: auto;
        cursor: pointer;
    }

}