.dynamic-circle-comp-main-container {
    position: absolute;
    width: 100%;
    height: 18vw;
    // background-color: #444;
    z-index: 3;
    bottom: -1vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    opacity: 0;
    transition: 2.2s ease-in-out;
    transform: translateY(40vh);



    .dynamic-circle-comp-main {
        position: absolute;
        top: 5vw;
        width: 180vw;
        height: 180vw;
        border-radius: 50%;
        // background-color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        // overflow: hidden;
        // z-index: 1  ;
        padding: 2px;

        .gradient-div {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            // background: linear-gradient(180deg, #69FF6A -10.6%, #000100 30.57%);
            background: radial-gradient(277.52% 84.54% at 22.38% -9.3%, #69FF6A 25.08%, #000100 30.17%);
        }

        .inner-circle-container {
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #000;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            .dynamic-circle-btn-styles {
                position: absolute;
                background: none;
                cursor: pointer;
                z-index: 1;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;

            }

            .left-btn {
                @extend .dynamic-circle-btn-styles;
                width: 3vw;
                height: 3vw;
                border-radius: 50%;
                top: 2.5%;
                margin-left: -20vw;


            }

            .hidden-layer-left {
                @extend .dynamic-circle-btn-styles;
                width: 3vw;
                height: 3vw;
                border-radius: 50%;
                top: 2.5%;
                margin-left: -20vw;
                // background-color: #fff;
                cursor: auto;
            }

            .right-btn {
                @extend .dynamic-circle-btn-styles;
                width: 3vw;
                height: 3vw;
                border-radius: 50%;
                top: 2.5%;
                margin-right: -20vw;

            }

            .hidden-layer-right {
                @extend .dynamic-circle-btn-styles;
                // background-color: #fff;
                width: 3vw;
                height: 3vw;
                border-radius: 50%;
                top: 2.5%;
                margin-right: -20vw;
                cursor: auto;
            }

            .book-now-btn {
                @extend .dynamic-circle-btn-styles;
                width: 13vw;
                height: 4vw;
                top: 2.2%;
                border-radius: 100px;
                background-color: #000;
                transition: 1.6s ease-out;
            }


            .card-container {
                position: absolute;
                width: 105%;
                height: 105%;
                border-radius: 50%;
                color: #fff;
                // border: 1px solid #fff;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                // gap: 50%;
                transition: 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                // transform: rotate(23.2deg);

                .navigation-link {
                    position: absolute;
                    width: 12vw;
                    height: 4.5vw;
                    // border: 1px solid yellow;
                    margin-top: -0.3vw;
                    z-index: 2;
                    cursor: pointer;
                }


                .city-card {
                    position: absolute;
                    color: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    transition: 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                    font-size: 1.15vw;
                    font-weight: 300;
                    gap: -0.1vw;


                    @keyframes iconBlinkKF {
                        0% {
                            filter: drop-shadow(0 0 4px #69FF6A);

                        }

                        25% {
                            filter: none;

                        }

                        50% {
                            filter: drop-shadow(0 0 4px #69FF6A);

                        }

                        75% {
                            filter: none;

                        }

                        100% {
                            filter: drop-shadow(0 0 4px #69FF6A);

                        }

                    }

                    .card-icon {
                        width: 1vw;
                        height: 1vw;
                        object-fit: contain;
                        transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

                    }

                    .date-span {
                        transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                        font-weight: 500;
                        text-transform: capitalize;
                        margin-bottom: -0.15vw;
                        color: #55da57;


                    }

                    .city-name-span {
                        text-transform: uppercase;
                        transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                        color: #37CAEE;
                    }

                    &:hover {
                        .card-icon {
                            transform: scale(1.2);
                            filter: drop-shadow(0 0 4px #55da57);
                            margin-bottom: 0.1vw;

                        }

                        .date-span {
                            transform: scale(1.2);
                            margin-bottom: 0.1vw;
                            color: #55da57;

                        }

                        .city-name-span {
                            transform: scale(1.2);

                        }
                    }
                }

            }
        }



    }
}