.terms-section {
  width: 100vw;
  color: white;
  background-color: black;
  padding: 5% 5%;
  
  .terms-para {
    font-size: 1.15vw;
    line-height: 1.75vw;
    color: rgb(177, 173, 173);
  }
  .terms-para2 {
    font-size: 1.2vw;
    line-height: 1.75vw;
    color: rgb(177, 173, 173);
    span{
      color: #0df40f;
      opacity: 0.8;
      font-size: 1.3vw;
    }
  }
  .terms-para2-extra {
    font-size: 1.15vw;
    line-height: 1.75vw;
    color: rgb(177, 173, 173);
    span{
      color: #ffffff;
      font-size: 1.25vw;
      font-style: italic;
    }
   
  }
  .list-item {
    font-size: 1.15vw;
    line-height: 1.75vw;
    color: rgb(177, 173, 173);
  }
  .terms-heading {
    font-size: 1.8vw;
    font-weight: 500;
    text-transform: uppercase;
    margin: 2.5% 0%;
    color: #0df40f;
    opacity: 0.9;

  }
  .terms-heading2 {
    font-size: 1.6vw;
    font-weight: 500;
    text-transform: uppercase;
    margin: 2.5% 0%;
    color: #0df40f;
    opacity: 0.8;
  }
  .terms-subheading {
    font-size: 1.3vw;
    font-style: italic;
    margin: 1.5% 0%;
    opacity: 0.9;
    text-decoration: underline;
    // padding: 1vw 0vw;
  }
  .span-extra{
    color:#0df40f;
    font-weight:500;
  }
}
