.forging-connection-main-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    transition: 1.6s cubic-bezier(.25, .08, .02, .98);
    // z-index: 1;
    overflow: hidden;
    top: 100%;

    .forging-connection-main {
        position: relative;
        width: 100%;
        height: 100%;
        // background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        // ------------------------------------------------------------

        .upper-layer-div {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #000000e1;
            z-index: 3;
            opacity: 0;
            transition: 0.6s cubic-bezier(.25, .08, .21, 1.05);


            .stop-ribbon-style {
                position: absolute;
                width: 120%;
                height: 2.5vw;
                // border: 1px solid #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 1.4s cubic-bezier(.25, .08, .21, 1.05);
                // mix-blend-mode:overlay;

                .ribbon-img {
                    position: absolute;
                    min-width: 100vw;
                    height: 2.5vw;
                    object-fit: contain;
                    transition: 1.4s cubic-bezier(.25, .08, .21, 1.05);

                }
            }

            .stop-ribbon1-div {
                @extend .stop-ribbon-style;
                transform: rotate(10deg) translate(-5vw, 60vh);

                .ribbon-img {
                    clip: rect(0px, 00vw, 10vw, 0vw);
                    transition-delay: 1.2s
                }

            }

            .stop-ribbon2-div {
                @extend .stop-ribbon-style;
                transform: rotate(10deg) translate(-5vw, 30vh);

                .ribbon-img {
                    clip: rect(0px, 120vw, 10vw, 120vw);
                    transition-delay: 0.8s
                }
            }

            .stop-ribbon3-div {
                @extend .stop-ribbon-style;
                transform: rotate(-10deg) translate(-15vw, 40vh);

                .ribbon-img {
                    clip: rect(0px, 00vw, 10vw, 0vw);
                    transition-delay: 0.8s
                }
            }

            .stop-ribbon4-div {
                @extend .stop-ribbon-style;
                transform: rotate(-6deg) translate(-14vw, 26vh) scaleX(-1);
                opacity: 0.6;

                .ribbon-img {
                    clip: rect(0px, 00vw, 10vw, 0vw);
                    transition-delay: 1.8s
                }
            }

            .stop-ribbon5-div {
                @extend .stop-ribbon-style;
                transform: rotate(-1deg) translate(-10vw, 50vh);
                opacity: 0.6;

                .ribbon-img {
                    clip: rect(0px, 120vw, 10vw, 120vw);
                    transition-delay: 1.8s
                }
            }

            .stop-ribbon6-div {
                @extend .stop-ribbon-style;
                transform: rotate(2deg) translate(-10vw, 75vh);
                opacity: 0.6;

                .ribbon-img {
                    clip: rect(0px, 00vw, 10vw, 0vw);
                    transition-delay: 1.8s
                }
            }

            .stop-ribbon7-div {
                @extend .stop-ribbon-style;
                transform: rotate(-2deg) translate(-10vw, 18vh);
                opacity: 0.6;

                .ribbon-img {
                    clip: rect(0px, 00vw, 10vw, 0vw);
                    transition-delay: 1.6s
                }
            }

        }




        // ------------------------------------------------------------

        .cube-container-div {
            position: absolute;
            width: 22vw;
            height: 22vw;
            // border: 1px solid #fff;
            // color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;

            .cube-img {
                position: relative;
                width: 80%;
                height: 80%;
                object-fit: contain;
                transform: rotate(-80deg) scale(0.7);
                transition: 2.6s cubic-bezier(.25, .08, .21, 1.05);
                z-index: 1;

            }

            .vector-div-one {
                position: absolute;
                width: 20vw;
                height: 4vw;
                // background-color: #fff;
                z-index: 0;
                transform: translate(-0vw, -2.8vw) rotate(-15deg);
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 1s cubic-bezier(.25, .08, .21, 1.05) 1.4s;
                opacity: 0;

                img {
                    width: 25%;
                    object-fit: contain;
                    margin-left: -25%;
                    transition: 0.8s cubic-bezier(.25, .08, .21, 1.05) 1.5s;

                }
            }
        }

        .hand-style {
            position: absolute;
            width: 32vw;
            height: 20vw;
            display: flex;
            justify-content: center;
            align-items: center;
            // background-color: #000;
            transition: 2.6s cubic-bezier(.25, .08, .21, 1.05);

            .hand-img {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: contain;
                z-index: 1;
            }

        }

        .left-hand-div {
            @extend .hand-style;
            left: -0.2vw;
            margin-top: 17.6vh;
            margin-left: -10vw
        }

        .right-hand-div {
            @extend .hand-style;
            right: -2vw;
            margin-top: -15vh;
            margin-right: -10vw;

            .vector-div-two {
                position: absolute;
                width: 14vw;
                height: 4vw;
                // background-color: #555;
                z-index: 0;
                transform: translate(-6vw, -0.2vw) rotate(-15deg);
                opacity: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 1s cubic-bezier(.25, .08, .21, 1.05) 0.8s;

                img {
                    width: 26%;
                    object-fit: contain;
                    margin-left: -0.6vw;
                }
            }
        }

        .lower-text-content {
            position: absolute;
            color: #fff;
            font-size: 3vw;
            font-weight: 300;
            line-height: 0.85;
            left: 14vw;
            margin-top: 65vh;
            text-transform: uppercase;
            transform: translate(-6vw, -2vw);
            transition: 0.8s cubic-bezier(.25, .08, .21, 1.05) 1.6s;
            font-style: italic;
            font-weight: 700;

            // .span0 {
            //     font-weight: 700;
            // }

            .span1 {
                // font-weight: 700;
                color: #55da57;
            }

            .span2 {
                // font-weight: 700;
                font-style: italic;
                color: #55da57;

            }
            // b{
            //     font-weight: 700;

            // }

            .bottom-hr {
                width: 105%;
                height: 2px;
                margin-left: -5%;
                border-radius: 100px;
                background: #ffffff91;
                margin-top: 0.8vw;
                opacity: 0;
                transition: 1.6s cubic-bezier(.25, .08, .21, 1.05) 1.5s;


            }

        }

        .upper-text-content {
            position: absolute;
            color: #fff;
            font-size: 2.15vw;
            font-weight: 300;
            line-height: 0.86;
            right: 10vw;
            margin-top: -65vh;
            text-transform: uppercase;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            transition: 0.8s ease-out 1s;


            b {
                display: flex;
                font-style: italic;
                font-size: 3vw;

                .span-img {
                    width: 3.5vw;
                    // border: 1px solid #fff;
                    font-style: normal;
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }

            .bold-span {
                color: #55da57;
            }

            .bottom-hr2 {
                width: 100%;
                height: 2px;
                border-radius: 100px;
                background: linear-gradient(90deg, #ffffff91 100%, #000 98.85%);
                margin-top: 0.8vw;
                transition: 1.6s ease-out 1.5s;

            }

        }


    }
}