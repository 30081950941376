.calendar-one-main-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    background: black;
    color: white;
    // z-index: 1;
    z-index: 4;
    transition: 1.8s ease-in-out;

    .event-highlights-content-container{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.9);
        z-index: 15;
    }

    .calendar-one-main {
        position: relative;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .bottom-progress-bar {
            position: absolute;
            left: 0;
            bottom: 0.5vh;
            width: 10%;
            height: 0.5vh;
            z-index: 4;
            background-color: #55da57;
            transform: translate(0, 100%);
            transition: 1.8s ease-out;
        }

        .phygital-event-text-div {
            position: absolute;
            width: 50%;
            height: 20vw;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            transform: translate(0, 80vh);
            transition: 1.2s ease-out;

            .phygital-event-heading {
                position: absolute;
                font-size: 4.5vw;
                font-weight: 500;
                letter-spacing: -0.05em;
                background: linear-gradient(to bottom right, white, black);
                padding: 10px;
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
                line-height: 3.8vw;
                z-index: 3;

                .green-text {
                    color: #55da57;
                }

                .event-text {
                    color: white;
                    font-size: 2vw;
                    font-weight: 400;
                    letter-spacing: 2vw;
                    margin-left: 1vw;
                }
            }

            .event-highlights-btn {
                margin-top: 55vh;
                border-radius: 20px;
                cursor: pointer;
                transition: background 0.3s ease;
                cursor: pointer;
                // border: 1px solid #fff;


                button {
                    z-index: 10;
                    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 100%, rgba(0, 0, 0, 0) 100%);
                    border-radius: 5vw;
                    opacity: 0.8;
                    padding: 0.6vw 1.2vw;
                    border: none;
                    font-size: 1.25em;
                    color: #55da57;
                    font-weight: 400;
                    cursor: pointer;
                }

                // .click-icon {
                //     position: absolute;
                //     width: 1.75em;
                //     margin-left: -3.5em;
                //     margin-top: 0.5em;
                // }
            }
        }

        .phygital-event-sub-text {
            position: absolute;
            font-size: 1.25vw;
            font-weight: 200;
            text-align: center;
            color: #55da57;
            z-index: 1;
            background: linear-gradient(to bottom, white, black);
            -webkit-background-clip: text;
            background-clip: text;
            transform: scale(-1, -1);
            transition: 1.2s ease-out;
        }

        .green-blob {
            position: absolute;
            object-fit: contain;
            transition: 1.2s ease-out;
            width: 60vw;
            transform: translate(-1vw, 100vh) rotate(-180deg);
        }

        .masking-div {
            top: 0;
            left: 0;
            z-index: 2;
            position: fixed;
            width: 35vw;
            height: 100vh;
            background-color: #000;
            transition: 2s ease-out;
            opacity: 0;
            cursor: none;

            .digital-masked-text {
                position: absolute;
                top: 5vh;
                left: 0;
                font-size: 10vw;
                font-weight: 500;
                letter-spacing: -0.05em;
                background: linear-gradient(to bottom, white, black 50%);
                padding: 20px;
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
                line-height: 6.5vw;
                opacity: 0;
                transition: 2.8s ease-in-out;

                .events-text {
                    background: linear-gradient(to bottom, white, black 80%);
                    padding: 20px;
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: transparent;
                    margin-left: -1vw;
                }
            }

            .masked-img {
                top: 0%;
                left: 0%;
                width: 35vw;
                height: 100%;
                object-fit: cover;
                mask-size: 18vw;
                mask-repeat: no-repeat;
                mask-position: center;
                opacity: 0.9;
                z-index: 3;
            }
        }

        .black-curtain-one {
            top: 0;
            left: 0;
            z-index: 1;
            position: fixed;
            width: 40vw;
            height: 70vh;
            background-color: #000;
            transition: 2s ease-out;
            opacity: 0;
        }

        .digital-physical-text-div {
            position: absolute;
            height: 20vw;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            z-index: 1;
            transform: translate(50vw, 80vh) rotate(-20deg);
            transition: 2s ease-out;

            .digital-text {
                font-size: 4.5vw;
                font-weight: 500;
                letter-spacing: -0.05em;
                color: white;
                line-height: 3.8vw;
                transition: 1.2s ease-out;
            }

            .eventOne-text {
                font-size: 4vw;
                font-weight: 500;
                letter-spacing: 0.4vw;
                margin-left: 0.6vw;
                color: #55da57;
                line-height: 3.8vw;
                transition: 1.2s ease-out;
            }

            .physical-text {
                font-size: 4vw;
                font-weight: 500;
                letter-spacing: -0.05em;
                margin-left: 0.6vw;
                color: #55da57;
                line-height: 3.8vw;
                transition: 1.2s ease-out;
                position: absolute;
                opacity: 0;
            }

            .ampersand {
                position: absolute;
                font-size: 10vw;
                margin-left: 19vw;
                background: linear-gradient(to bottom, white, black 80%);
                padding: 20px;
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
                opacity: 0.5;
                transform: scale(-1, 1);
                transition: 1.2s ease-out;
            }

            .eventTwo-text {
                position: absolute;
                font-size: 4vw;
                font-weight: 500;
                letter-spacing: 0.4vw;
                color: white;
                margin-left: 0.6vw;
                transition: 1.2s ease-out;
                opacity: 0;
            }
        }

        .para-scroll-div {
            position: absolute;
            width: 100%;
            height: 70vh;
            display: flex;
            // transform: translate(50vw, 80vh) rotate(-20deg);
            overflow: hidden;
            z-index: 1;
            transition: 2s ease-out;

            .para-scroll-bg {
                right: -10vw;
                position: absolute;
                width: 50vw;
                height: 65vh;
                border-radius: 64px;
                background: linear-gradient(to bottom, black, rgb(54, 54, 54));
                opacity: 0.5;
                transform: translate(0vw, 80vh);
                overflow: hidden;
                z-index: 0;
                transition: 2s ease-out;
            }
        }

        .v-grid-div {
            position: absolute;
            z-index: 2;
            margin-bottom: -55vh;
            margin-left: -70vw;
            transform: translate(0, 100vh);
            transition: 2s ease-out;
            opacity: 0;
            cursor: none;


            .v-grid-img {
                width: 100%;
                height: 100vh;
                object-fit: cover;
                // opacity: 0.7;
            }
        }

        .physical-green-bg {
            width: 100vw;
            height: 100vh;
            transition: 2s ease-out;
        }

        .black-curtain-two {
            top: 0;
            right: 0;
            position: fixed;
            width: 100vw;
            height: 100vh;
            background-color: black;
            transform: translate(0, 150vh) rotate(-40deg);
            transition: 1.5s ease-out;
            z-index: 1;
            opacity: 0;
        }

        .physical-event-green {
            position: absolute;
            object-fit: contain;
            transition: 2s ease-out;
            left: 0;
            top: 40vh;
            transform: translate(-50%, -50%);
            // transform: translate(0, 100vh);

            .physical-text {
                position: absolute;
                font-size: 5vw;
                font-weight: 500;
                letter-spacing: -0.1vw;
                color: #55da57;
                margin-left: 6vw;
                transition: 1.2s ease-out;
            }

            .events-text {
                position: absolute;
                font-size: 5vw;
                font-weight: 500;
                letter-spacing: -0.2vw;
                color: white;
                margin-left: 6vw;
                transition: 2s ease-out;
                opacity: 0;
            }
        }

        .physical-event-grid {
            position: absolute;
            top: 10vh;
            left: -1vw;
            width: 30vw;
            height: 30vh;
            transform: translate(-40vw, 120vh) rotate(-90deg);
            transition: 2s ease-out;
            z-index: 1;

            .grid-bg-one {
                position: relative;
                width: 100%;
                height: 100%;
                border-top-right-radius: 128px;
                background: linear-gradient(#06c209 0%, rgba(0, 0, 0, 1) 50%);

                .grid-bg-black-one {
                    position: absolute;
                    width: 99%;
                    height: 99%;
                    top: 0.5%;
                    left: 0.5%;
                    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgb(54, 54, 54));
                    border-top-right-radius: 128px;

                }

                .physical-event-img-one {
                    position: absolute;
                    width: 98%;
                    height: 98%;
                    top: 1%;
                    left: 1%;
                    object-fit: cover;
                    border-top-right-radius: 128px;
                }
            }

            .grid-bg-two {
                position: relative;
                width: 100%;
                height: 100%;
                margin-top: 5vh;
                border-bottom-right-radius: 128px;
                background: linear-gradient(to top, #06c209 0%, rgba(0, 0, 0, 1) 50%);
                transition: 2s ease-out;

                .grid-bg-black-two {
                    position: absolute;
                    width: 99%;
                    height: 99%;
                    top: 0.5%;
                    right: 0.5%;
                    background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgb(54, 54, 54));
                    border-bottom-right-radius: 128px;
                }

                .physical-event-img-two {
                    position: absolute;
                    width: 98%;
                    height: 98%;
                    top: 1%;
                    right: 1%;
                    object-fit: cover;
                    border-bottom-right-radius: 128px;
                }
            }

            .grid-blob {
                position: absolute;
                width: 18vw;
                left: 5vw;
                object-fit: contain;
                z-index: 1;
                transform: translate(-5vw, 15vh) rotate(-80deg);
                opacity: 0;
                transition: 2s ease-out;
            }
        }

        .calendar-one-bg-div {
            position: absolute;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-blend-mode: darken;
            transition: 2s ease-in-out;
            z-index: 0;
            opacity: 0;
        }

        .overlay {
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: green;
            transition: 2s ease-out;
            mix-blend-mode: darken;
        }

        .overlay-second {
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #55da57;
            transition: 2s ease-out;
            opacity: 0;
            mix-blend-mode: hue;
        }

        .green-bulb {
            position: absolute;
            object-fit: contain;
            transition: 1.8s ease-out;
            z-index: 1;
            width: 35vw;
        }

        .days-text-div {
            position: absolute;
            width: 100%;
            height: 20vw;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 2;
            transform: translate(0, 80vh);
            transition: 1.8s ease-out;

            .green-dot {
                position: absolute;
                width: 0.9vw;
                height: 0.9vw;
                border-radius: 50%;
                background-color: #55da57;
                z-index: 1;
                transform: translate(-35vw, -0.8vw);
                transition: 2s ease-out;
                opacity: 0;
            }

            p {
                position: absolute;
                font-size: 6vw;
                font-weight: 600;
                text-align: center;
                color: white;
                line-height: 2.9vw;
                transition: 2s ease-out;
                z-index: 0;

                .days-span {
                    position: relative;
                    font-size: 5vw;
                    font-weight: 700;
                    letter-spacing: -0.09em;
                    z-index: -1;
                    background-clip: text;
                    background-image: linear-gradient(to bottom, #55da57, transparent);
                    color: black;
                    padding: calc(calc(1em / 16) * 2);
                    -webkit-text-stroke-color: transparent;
                    -webkit-text-stroke-width: calc(0.5em / 16);
                }
            }

            .sub-text {
                font-size: 3vw;
                margin-top: 3.5vw;
                margin-left: 0.5vw;
                font-weight: 500;
                font-style: italic;
                border-top: 1px solid white;
                border-bottom: 1px solid white;
                padding: 1vw 0;
                transform: translate(30vw, 0);
                transition: 2s ease-out;
                opacity: 0;

                .physical-text {
                    color: #55da57;
                    opacity: 1;
                    transition: 1.5s ease-out;
                }

                .digital-text {
                    position: absolute;
                    color: #55da57;
                    letter-spacing: 0.05em;
                    margin-left: -11.5vw;
                    opacity: 0;
                    transition: 1.5s ease-out;
                }

                .green-dot-one {
                    position: absolute;
                    width: 0.5vw;
                    height: 0.5vw;
                    border-radius: 50%;
                    background-color: #55da57;
                    transform: translate(0vw, -4.1vw);
                }

                .green-dot-two {
                    position: absolute;
                    width: 0.5vw;
                    height: 0.5vw;
                    border-radius: 50%;
                    background-color: #55da57;
                    transform: translate(0vw, 0.8vw);
                    transition: 2.8s ease-in-out;
                }
            }

        }

        .img-hexagon-div {
            display: flex;
            position: absolute;
            width: fit-content;
            transform: translate(0, 130vh);
            transition: 1.8s ease-out;
            z-index: 2;

            .hexagon-grid {
                width: 40vw;
            }
        }

        .green-wave {
            position: absolute;
            top: 0;
            right: 0;
            width: 30%;
            height: 100%;
            transform: translate(40vw, 0);
            transition: 2s ease-out;
            filter: grayscale(40%);
           
        }

        .green-spring {
            position: absolute;
            object-fit: contain;
            right: 0;
            z-index: 1;
            width: 30vw;
            transform: translate(50vw, 0);
            transition: 2s ease-out;
        }

        .phygital-options-div {
            position: absolute;
            width: 35vw;
            height: 25vh;
            top: 55vh;
            left: 8vw;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 10%;
            transform: translate(0, 100vh);
            transition: 1.8s ease-out;

            .dark-green-bg {
                display: flex;
                width: 100%;
                height: 40%;
                justify-content: space-around;
                align-items: center;

                .white-bg-one {
                    width: 45%;
                    height: 90%;
                    background: linear-gradient(to left, white, transparent);
                    border-radius: 10px 32px 10px 10px;

                    .dark-green-bg-one {
                        display: flex;
                        height: 99%;
                        width: 99.7%;
                        background-color: green;
                        justify-content: space-around;
                        align-items: center;
                        border-radius: 10px 32px 10px 10px;

                        div {
                            margin-left: -4vw;
                        }

                        img {
                            position: absolute;
                            margin-left: 10.25vw;
                        }

                        p {
                            font-weight: 500;
                            font-size: 1.1vw;
                        }

                        .sub-text {
                            color: #55da57;
                            font-weight: 400;
                        }

                        .highlighted-text {
                            color: #79FF7A;
                            font-weight: 600;
                            font-size: 1.2vw;
                            text-shadow: 0px 0px 10px #000000;
                        }
                    }
                }

                .white-bg-two {
                    width: 45%;
                    height: 90%;
                    background: linear-gradient(to right, white, transparent);
                    border-radius: 32px 10px 10px 10px;

                    .dark-green-bg-two {
                        display: flex;
                        height: 99%;
                        width: 99.7%;
                        margin-left: 0.5%;
                        background-color: green;
                        justify-content: space-around;
                        align-items: center;
                        border-radius: 32px 10px 10px 10px;

                        div {
                            margin-left: -4vw;
                        }

                        img {
                            position: absolute;
                            margin-left: 10.25vw;
                        }

                        p {
                            font-weight: 500;
                            font-size: 1.1vw;
                        }

                        .sub-text {
                            color: white;
                            font-weight: 400;
                        }

                        .highlighted-text {
                            color: #79FF7A;
                            font-weight: 600;
                            font-size: 1.2vw;
                            text-shadow: 0px 0px 10px #000000;
                        }
                    }
                }
            }

            .green-bg {
                display: flex;
                width: 95%;
                height: 40%;
                background: linear-gradient(to bottom, green, transparent);
                justify-content: center;
                align-items: center;
                border-radius: 36px 36px 10px 10px;

                .green-bg-sub {
                    display: flex;
                    height: 90%;
                    width: 99%;
                    background-color: #55da57;
                    justify-content: center;
                    align-items: center;
                    border-radius: 36px 36px 10px 10px;

                    p {
                        color: black;
                        font-size: 1.2vw;
                        font-weight: 600;
                    }

                    img {
                        position: absolute;
                    }

                    .icon-bg-one {
                        margin-left: -20vw;
                    }

                    .icon-bg-two {
                        margin-left: 20vw;
                    }

                    .icon-three {
                        margin-left: -20vw;
                    }

                    .icon-four {
                        margin-left: 20vw;
                    }
                }
            }
        }

        .calendar-div {
            position: absolute;
            width: 40vw;
            height: 28vw;
            transform: translate(25vw, 100vh);
            transition: 1.8s ease-out;
            z-index: 2;
        }
    }
}